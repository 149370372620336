import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { useState } from "react";

interface DialogFulfillsFilenameProps {
  handleUpload: () => void;
  skipDialog?: boolean;
}

export const DialogFulfillsFilename = ({
  handleUpload,
  skipDialog,
}: DialogFulfillsFilenameProps) => {
  const [open, setOpen] = useState(false);

  const handleCloseModel = () => {
    setOpen(false);
    handleUpload();
  };

  if (skipDialog === true || skipDialog === undefined) {
    return (
      <Button appearance="primary" onClick={handleCloseModel}>
        Upload
      </Button>
    );
  }

  return (
    <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="primary">Upload</Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>
            Are you sure you have selected the right file and you want to continue?
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={handleCloseModel}>
              Upload anyway
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import { Subtitle1, Subtitle2 } from "@fluentui/react-components";
import TestSVG from "../assets/svg/TestSVG";

export default function DefaultError() {
  return (
    <main className="flex flex-col justify-center items-center h-screen w-screen gap-4">
      <TestSVG />
      <Subtitle1 as="h1">Something went wrong</Subtitle1>
      <Subtitle2 className="mb-12">
        An error has occurred, please attempt to reload the page. If the problem persists, contact
        your administrator for assistance.
      </Subtitle2>
    </main>
  );
}

import { ReactNode } from "react";
import { cn } from "../../../lib/utils";
import { TableMobileBodyCollapsible } from "../../table-collapsible/mobile/table-mobile-body-collapsible";
import { TableMobileRowContent } from "../../table-collapsible/mobile/table-mobile-row-content";
import { TableMobileRowTrigger } from "../../table-collapsible/mobile/table-mobile-row-trigger";
import {
  TimesheetTable,
  TimesheetTableFooter,
  TimesheetTableHeader,
} from "../../../models/TimesheetTable";

interface TableMobileProps {
  data: TimesheetTable[];
  dataHeader: TimesheetTableHeader[];
  dataFooter: TimesheetTableFooter[];
}

export function TableMobile({ data, dataHeader, dataFooter }: TableMobileProps) {
  return (
    <div className="w-full flex flex-col gap-4">
      <TableMobileBody data={data} dataHeader={dataHeader} />
      <TableMobileFooter data={dataFooter} />
    </div>
  );
}

interface TableMobileBodyProps {
  data: TimesheetTable[];
  dataHeader: TimesheetTableHeader[];
}

export function TableMobileBody({ data, dataHeader }: TableMobileBodyProps) {
  return (
    <>
      {data.map((invoices) => (
        <TableMobileBodyCollapsible key={invoices.projectId}>
          <TableMobileRowTrigger className="shadow-lg p-3 flex">
            <p className="font-semibold text-slate-700 dark:text-slate-300 helper-text-black">
              {invoices.projectFriendlyName}
            </p>
          </TableMobileRowTrigger>
          {invoices.children.map((task) => (
            <TableMobileRowContent key={task.taskId}>
              <TableMobileBodyCollapsible>
                <TableMobileRowTrigger className="pl-6 pr-6 py-4 font-semibold text-slate-700 dark:text-slate-300 border-b flex bg-gray-100 dark:bg-neutral-800 helper-text-black">
                  <p>{task.taskName}</p>
                </TableMobileRowTrigger>
                <TableMobileRowContent key={task.id}>
                  {task.children.map((item, i) => (
                    <Row key={item.id}>
                      {dataHeader[i].node}
                      <div className="w-24">{item.node}</div>
                    </Row>
                  ))}
                </TableMobileRowContent>
              </TableMobileBodyCollapsible>
            </TableMobileRowContent>
          ))}
        </TableMobileBodyCollapsible>
      ))}
    </>
  );
}

interface TableMobileFooterProps {
  data: TimesheetTableFooter[];
}

export const TableMobileFooter = ({ data }: TableMobileFooterProps) => {
  const availableHours = data.find((item) => item.key === "availableHours");
  const total = data.find((item) => item.key === "total");

  return (
    <Row className="bg-gray-100 dark:bg-neutral-800 mt-4 border-none">
      <p className="">Weekly Timesheet Total</p>
      <p className="px-6 font-semibold text-slate-700 dark:text-slate-300 max-w-[120px] rounded-lg helper-text-black">
        {availableHours?.hours} / {total?.hours}
      </p>
    </Row>
  );
};

function Row({ className, children }: { className?: string; children: ReactNode }) {
  return (
    <div
      className={cn("flex justify-between items-center border-b px-3 py-2 pl-8 gap-2", className)}
    >
      {children}
    </div>
  );
}

import { formatCompleteDate } from "../../lib/date";
import { cn } from "../../lib/utils";
import { DataImport } from "../../models/DataImport";
import { DialogDataImport } from "../dialog/dialog-data-import";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";

export interface DataImportTableProps {
  data?: DataImport[];
}

export default function DataImportTable({ data = [] }: DataImportTableProps) {
  return (
    <Table outerClassName="bg-gray-100 dark:bg-neutral-800 py-2 px-5 rounded-lg">
      <TableHeader>
        <TableRow>
          <TableHead className="font-medium text-start">Id</TableHead>
          <TableHead className="font-medium text-start">Date and Time</TableHead>
          <TableHead className="text-start">Imported By</TableHead>
          <TableHead className="text-start">File Name</TableHead>
          <TableHead className="text-start">Status</TableHead>
          <TableHead className="text-start">Success Count</TableHead>
          <TableHead className="text-start">Error Count</TableHead>
          <TableHead className="text-start">Count</TableHead>
          <TableHead className="text-start">Log</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((invoice) => (
          <TableRow key={invoice.id}>
            <TableCell className={cn("helper-text-black")}>{invoice.id}</TableCell>
            <TableCell className={cn("font-medium helper-text-black")}>
              {formatCompleteDate(new Date(invoice.timestamp))}
            </TableCell>
            <TableCell className={cn("helper-text-black")}>{invoice.importedByFullName}</TableCell>
            <TableCell className={cn("helper-text-black")}>{invoice.fileName}</TableCell>
            <TableCell
              className={cn(invoice.status === "Error" ? "!text-red-600" : "!text-green-600")}
            >
              {invoice.status}
            </TableCell>
            <TableCell className={cn("helper-text-black")}>{invoice.rowsCountSuccess}</TableCell>
            <TableCell className={cn("helper-text-black")}>{invoice.rowsCountError}</TableCell>
            <TableCell className={cn("helper-text-black")}>{invoice.rowsCount}</TableCell>
            <TableCell className={cn("helper-text-black")}>
              {invoice.status === "Error" ? (
                <DialogDataImport title="Error" description={invoice.errorMessage} />
              ) : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

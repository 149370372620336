// workaround

import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { Timesheet } from "../models/Timesheet";
import { TableBox1 } from "../components/table-collapsible/table-box";
import { TimesheetTableTaskDays } from "../models/TimesheetTable";
import { roundToNearestQuarter } from '../lib/utils'

const ValuesForFormTimesheetContext = createContext<{
  values: Timesheet[];
  setValues: React.Dispatch<React.SetStateAction<Timesheet[]>>;
  setValue: (id: string, currentValue: string) => void;
} | null>(null);

export function useValuesForFormTimesheetContext() {
  const context = useContext(ValuesForFormTimesheetContext);

  if (!context) {
    throw new Error("This context is for transfer values from Timesheet page");
  }

  return context;
}

export function ValuesForFormTimesheetContextProvider({ children }: { children: ReactNode }) {
  const [values, setValues] = useState<Timesheet[]>([]);

  const valueIsChanged = useCallback(
    (id: string, currentValue: string) => {
      let [projectId, taskId, day] = id.split("#@%");
      if (currentValue === "") currentValue = "0";
      const temp = values.map((items) => {
        if (items.projectId === projectId) {
          items.children.map((item) => {
            if (item.taskId === taskId) {
              item.children.map((day) => {
                if (day.id === id) {
                  day.hours = parseFloat(currentValue);
                }
                return day;
              });

              item.children.map((day) => {
                if (day.id === `${projectId}#@%${taskId}#@%sum`) {
                  const reducer = (acc = 0, curr: TimesheetTableTaskDays) => {
                    if (
                      curr.id !== `${projectId}#@%${taskId}#@%day1` &&
                      curr.id !== `${projectId}#@%${taskId}#@%day2` &&
                      curr.id !== `${projectId}#@%${taskId}#@%day3` &&
                      curr.id !== `${projectId}#@%${taskId}#@%day4` &&
                      curr.id !== `${projectId}#@%${taskId}#@%day5` &&
                      curr.id !== `${projectId}#@%${taskId}#@%day6` &&
                      curr.id !== `${projectId}#@%${taskId}#@%day7`
                    ) {
                      return acc;
                    } else {
                      return acc + roundToNearestQuarter(curr.hours);
                    }
                  };

                  const sumOfValues = item.children.reduce(reducer, 0);        
                      
                  day.node = (
                    <TableBox1
                      day={{ color: "transparent", id: day.id, hours: sumOfValues }}
                      className="px-6 py-4 font-semibold text-slate-700 dark:text-slate-300"
                      showZero
                    />
                  );
                }
                return day;
              });
            }
            return item;
          });
        }

        return items;
      });
      setValues(temp);
    },
    [values]
  );

  return (
    <ValuesForFormTimesheetContext.Provider value={{ values, setValues, setValue: valueIsChanged }}>
      {children}
    </ValuesForFormTimesheetContext.Provider>
  );
}

export default ValuesForFormTimesheetContext;

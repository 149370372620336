import { Filter20Regular, Navigation20Regular } from "@fluentui/react-icons";
import DayPicker from "../week-picker/DayPicker";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  DialogActions,
  Button,
} from "@fluentui/react-components";
import { useFiltersContext } from "../filters/filters-context";
import { SelectUsers } from "../select-users/select-users";
import { useEffect, useState } from "react";

export default function MobileNav() {
  //@TODO: Postoji greska sa filterom, ukoliko nema podataka, filter pokusa da isfiltrira i pukne
  const { filterNodes, showFilter } = useFiltersContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const closeDialog = (event: PopStateEvent) => {
      if (open) {
        setOpen(false);
        event.preventDefault();
      }
    };

    if (window.history) {
      if (open) {
        window.history.pushState(null, "");
      }
      window.addEventListener("popstate", closeDialog);
    }

    return () => {
      window.removeEventListener("popstate", closeDialog);
    };
  }, [open]);

  function handleOpen() {
    if (window.history && open) {
      window.history.back();
      console.log("open", open);
    }
    setOpen(!open);
  }

  return (
    <>
      <section className="w-full flex justify-between items-center gap-2 border-b border-gray-200 p-3">
        <div className="flex justify-center items-center gap-3">
          <Navigation20Regular />
          <DayPicker />
        </div>
        {showFilter && (
          <>
            <div className="border-l border-gray-200 h-5"></div>
            <div className="flex justify-center items-center gap-3">
              <Dialog open={open} onOpenChange={handleOpen}>
                <DialogTrigger disableButtonEnhancement>
                  <button className="flex items-center gap-3">
                    <Filter20Regular />
                    <p className="font-semibold text-slate-900 dark:text-slate-300">Filter</p>
                  </button>
                </DialogTrigger>
                <DialogSurface className="!h-96 min-h-screen !rounded-none">
                  <DialogBody>
                    <DialogContent children={filterNodes} />
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" className="!mt-9">
                          Filter
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>

              {/* <Search20Regular /> */}
            </div>
          </>
        )}
      </section>
      <SelectUsers />
    </>
  );
}

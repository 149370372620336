import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Textarea,
  TextareaProps,
} from "@fluentui/react-components";
import { useState, JSX } from "react";

interface DialogTextAreaProps {
  openDialogChildrenElement: JSX.Element;
  dialogActionButtonName: string;
  onDialogActionButton: (dialogTextValue: string) => void;
  title: string;
  textAreaPlaceHolder?: string;
}

export const DialogTextArea = ({
  openDialogChildrenElement,
  dialogActionButtonName,
  onDialogActionButton,
  title,
  textAreaPlaceHolder,
}: DialogTextAreaProps) => {
  const [open, setOpen] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");

  const handleClickedActionButton = () => {
    setOpen(false);
    console.log(textAreaValue);

    onDialogActionButton(textAreaValue);
    setTextAreaValue("");
  };

  const onChange: TextareaProps["onChange"] = (ev, data) => {
    if (data.value.length <= 1000) {
      setTextAreaValue(data.value);
    }
  };
  const onOpenDialogChange = (isOpen: boolean) => {
    setOpen(isOpen);
    setTextAreaValue("");
  };

  return (
    <Dialog open={open} onOpenChange={(event, data) => onOpenDialogChange(data.open)}>
      <DialogTrigger disableButtonEnhancement>{openDialogChildrenElement}</DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Textarea
              value={textAreaValue}
              onChange={onChange}
              rows={5}
              size="medium"
              className="w-full px-0 text-sm text-gray-900 dark:text-white bg-white dark:bg-neutral-800 border-0 focus:ring-0 dark:placeholder-gray-400"
              resize="horizontal"
              placeholder={textAreaPlaceHolder ? textAreaPlaceHolder : "Insert the reason here..."}
            />
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={handleClickedActionButton}>
              {dialogActionButtonName}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

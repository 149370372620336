import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { Sidebar } from "../ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { cn } from "../../lib/utils";

export default function SidebarDesktopTimesheets() {
  let location = useLocation();
  return (
    <Sidebar>
      <Accordion>
        {sidebarData.map((data) => (
          <AccordionItem key={`item_${data.id}`} value={`item_${data.id}`}>
            <AccordionHeader>{data.label}</AccordionHeader>
            <AccordionPanel>
              {data.children.map((item) => (
                <div key={`item_panel_${item.id}`} className="p-2">
                  <Link to={item.url}>
                    <p className={cn(location.pathname === item.url && "font-bold")}>
                      {item.id}. {item.label}
                    </p>
                  </Link>
                </div>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Sidebar>
  );
}
export const sidebarData = [
  {
    id: 1,
    label: "Priority 1 Tables",
    children: [
      {
        id: 1,
        label: "Expense Types",
        url: "/expense-types",
      },
      {
        id: 2,
        label: "Default Hour Limits",
        url: "/default-hour-limits",
      },
      {
        id: 3,
        label: "Resource Types",
        url: "/resource-types",
      },
      {
        id: 4,
        label: "Job Titles",
        url: "/job-titles",
      },
      {
        id: 5,
        label: "Positions",
        url: "/positions",
      },
      {
        id: 6,
        label: "Work Types",
        url: "/work-types",
      },
      {
        id: 7,
        label: "Cost Centers",
        url: "/cost-centers",
      },
      {
        id: 8,
        label: "Working Days",
        url: "/working-days",
      },
    ],
  },
  {
    id: 2,
    label: "Priority 2 Tables",
    children: [
      {
        id: 1,
        label: "Task Lifecycle Phases",
        url: "/task-lifecycle-phases",
      },
      {
        id: 2,
        label: "Resources",
        url: "/resources",
      },
      {
        id: 3,
        label: "Projects",
        url: "/projects",
      },
    ]
  },
  {
    id: 3, 
    label: "Priority 3 Tables", 
    children: [
      {
        id: 1,
        label: "Tasks",
        url: "/tasks",
      },
      {
        id: 2,
        label: "Project Managers",
        url: "/project-managers",
      },
      {
        id: 3,
        label: "Resource Managers",
        url: "/resource-managers",
      },
    ] 
  },
  {
    id: 4, 
    label: "Priority 4 Tables", 
    children: [
      {
        id: 1,
        label: "Project Assignments",
        url: "/project-assignments",
      },
    ] 
  },
];

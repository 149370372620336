import { Routes, Route, Navigate } from "react-router-dom";
import Privacy from "../pages/privacy";
import TermsOfUse from "../pages/terms-of-use";
import Timesheet from "../pages/timesheet";
import TimesheetByProjectManager from "../pages/timesheet-by-project-manager";
import TimesheetByResourceManager from "../pages/timesheet-by-resource-manager";
import LayoutTimesheets from "../layouts/layout-timesheets";
//check error redirection with error boundary
import NotAuthorised from "../pages/not-authorised";
import Forbidden from "../pages/forbidden";
import ForbiddenPM from "../pages/forbidden-pm";
import ForbiddenRM from "../pages/forbidden-rm";
import ForbiddenImport from "../pages/forbidden-import";
import DefaultError from "../pages/error-default";
import { UserData } from "../models/UserData";

import Layout from "../layouts/layout";
import ExpenseTypes from "../pages/data-import/expense-types";
import CostCenters from "../pages/data-import/cost-centers";
import Holidays from "../pages/data-import/holidays";
import JobTitles from "../pages/data-import/job-titles";
import Positions from "../pages/data-import/positions";
import ResourceTypes from "../pages/data-import/resource-types";
import ResourceManager from "../pages/data-import/resource-managers";
import Projects from "../pages/data-import/projects";
import ProjectManagers from "../pages/data-import/project-managers";
import ProjectAssignments from "../pages/data-import/project-assignments";
import WorkingDays from "../pages/data-import/working-days";
import WorkTypes from "../pages/data-import/work-types";
import DefaultHourLimits from "../pages/data-import/default-hour-limits";
import Tasks from "../pages/data-import/tasks";
import TaskLifeCyclePhases from "../pages/data-import/task-lifecycle-phases";
import Resources from "../pages/data-import/resources";
import { useSessionStorage } from "usehooks-ts";
import PowerBiReport from "../pages/powerbi-report";

export default function Router() {
  const [user] = useSessionStorage<UserData>("user", {
    resourceId: null,
    maxHoursPerDay: 0,
    isR: false,
    isPM: false,
    isRM: false,
    isAdmin: false,
  });

  return (
    <Routes>
      <Route path="/not-authorised" element={<NotAuthorised />} />
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="/forbiddenPM" element={<ForbiddenPM />} />
      <Route path="/forbiddenRM" element={<ForbiddenRM />} />
      <Route path="/forbidden-import" element={<ForbiddenImport />} />
      <Route path="/default-error" element={<DefaultError />} />

      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route element={<Layout />}>
        <Route path="/timesheet" element={<Timesheet />} />
        <Route path="/timesheet-by-project-manager" element={<TimesheetByProjectManager />} />
        <Route path="/timesheet-by-resource-manager" element={<TimesheetByResourceManager />} />
      </Route>

      <Route element={<LayoutTimesheets />}>
        <Route path="expense-types" element={<ExpenseTypes />} />
        <Route path="cost-centers" element={<CostCenters />} />
        <Route path="holidays" element={<Holidays />} />
        <Route path="job-titles" element={<JobTitles />} />
        <Route path="positions" element={<Positions />} />
        <Route path="project-assignments" element={<ProjectAssignments />} />
        <Route path="project-managers" element={<ProjectManagers />} />
        <Route path="projects" element={<Projects />} />
        <Route path="resource-managers" element={<ResourceManager />} />
        <Route path="resource-types" element={<ResourceTypes />} />
        <Route path="resources" element={<Resources />} />
        <Route path="task-lifecycle-phases" element={<TaskLifeCyclePhases />} />
        <Route path="tasks" element={<Tasks />} />
        <Route path="working-days" element={<WorkingDays />} />
        <Route path="work-types" element={<WorkTypes />} />
        <Route path="default-hour-limits" element={<DefaultHourLimits />} />
      </Route>
      <Route path="/powerbi-report" element={<PowerBiReport />} />

      <Route path="*" element={<Navigate to={"/timesheet"} />}></Route>
    </Routes>
  );
}

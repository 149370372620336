import { merge } from "lodash";
import * as microsoftTeams from "@microsoft/teams-js";
import { ApiError, ApiErrorCode, isApiError } from "../models/ApiError";
import {BASE_URL} from "../models/envUrl";

// This function is for callers where authentication is required.
// It makes a fetch client call with an AzureAD token.
export async function authFetch<T>(urlPath: string, init?: RequestInit) {
  const token = await microsoftTeams.authentication.getAuthToken().catch((err) => {

    const apiError: ApiError = {
      errorCode: ApiErrorCode.Unauthorized,
      time: Date.now().toString(),
      title: "Unable to get MST Auth token ",
      message: "Microsoft teams authentication threw an error while extracting the sso token",
    };
    console.log(`Unable to get Auth token ${err}`)
    throw apiError;
  });
  return (await fetchWithToken(urlPath, token, init)) as T;
}

async function fetchWithToken<T>(urlPath: string, token: string, init?: RequestInit) {
  const mergedInit = merge({}, init, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return (await fetchClient(urlPath, mergedInit)) as T;
}

async function fetchClient(urlPath: string, mergedInit: RequestInit): Promise<any> {
  const response = await fetch(`https://${BASE_URL}/api/v1/${urlPath}`, mergedInit);

  if (!response.ok) {
    const errorJson: any = await response.json();
    console.log(errorJson);

    if(isApiError(errorJson)){
      throw errorJson;
    }

    if (!errorJson) {
      throw new Error("Error fetching data.");
    }

    if (errorJson.title) {
      throw new Error(`${errorJson.title}`);
    }

    throw new Error(`${errorJson.ErrorCode}: ${errorJson.Message}`);
  }

  return await response.json();
}

import React, { ReactNode } from "react";
import clsx from "clsx";
import { useTableCollapsibleContext } from "../table-collapsible-context";
import { CaretDown20Regular, CaretUp20Regular } from "@fluentui/react-icons";

interface TableMobileRowTriggerProps {
  children: ReactNode;
  className?: string;
}

export const TableMobileRowTrigger = ({ className, children }: TableMobileRowTriggerProps) => {
  const { open, onOpenToggle } = useTableCollapsibleContext();

  const selectIcon = () => {
    if (open) {
      return <CaretUp20Regular />;
    }
    return <CaretDown20Regular />;
  };

  return (
    <div
      onClick={onOpenToggle}
      className={clsx("bg-white dark:bg-neutral-800 shadow-lg p-3 flex", className)}
    >
      {selectIcon()}
      {children}
    </div>
  );
};

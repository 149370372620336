import * as React from "react";

import { cn, formatTextToDecimal, roundToNearestQuarter } from "../../../lib/utils";
import { DayColor, TimesheetDay } from "../../../models/Timesheet";
import { useTableInputContext } from "./table-input-context";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useValuesFromFormContext } from "../../../contexts/ValuesFromFormContext";
import { useValuesForFormTimesheetContext } from "../../../contexts/ValuesForFormTimesheetContext";

export interface TableInputProps {
  day: TimesheetDay;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  className: string;
}

const TableInput = ({ day, register, setValue, className }: TableInputProps) => {
  const { disabled } = useTableInputContext();
  const { setValue: setValueContext } = useValuesFromFormContext();
  const { setValue: setValueTimesheet } = useValuesForFormTimesheetContext();


  function onChange(value: any) {
    let valueString = value.target.value;
    let number = Number(value.target.value);
    if (Number.isNaN(number) || number < 0) {
      valueString = "";
    }

    if (valueString.indexOf(".") >= 0) {
      valueString = valueString.slice(0, valueString.indexOf(".") + 3);
    }

    value.target.value = valueString;
    setValue(`timesheet.${day.id}`, valueString);
    setValueContext(day.id, valueString);
  }
  
  function onBlur(value: any) {
    const newValue = formatTextToDecimal(roundToNearestQuarter(value.target.value).toString());
    
    value.target.value = newValue;
    setValue(`timesheet.${day.id}`, newValue);
    setValueContext(day.id, newValue);
    setValueTimesheet(day.id, newValue);
  }

  const formatNumber = (value: number) => {
    return formatTextToDecimal(roundToNearestQuarter(value).toString());
  };

  function validateHours(value: unknown[]) {
    console.log("value", value);


    return true;
  }
  function minHoursPerDay(value: unknown[], values: FieldValues) {
    const currentDay = day.id.split("#@%")[2];

    let hoursSum = 0;

    for (const key in values.timesheet) {
      let [projectId, taskId, day] = key.split("#@%");

      if (day === currentDay && !!values.timesheet[key]) {
        hoursSum += parseFloat(values.timesheet[key]);
      }
    }

    if (hoursSum < 0) {
      return false;

    }

    return true;
  }
  function maxHoursPerDay(value: unknown[], values: FieldValues) {
    const currentDay = day.id.split("#@%")[2];

    let hoursSum = 0;

    for (const key in values.timesheet) {
      let [projectId, taskId, day] = key.split("#@%");

      if (day === currentDay && !!values.timesheet[key]) {
        hoursSum += parseFloat(values.timesheet[key]);
      }
    }

    if (hoursSum > 50) {
      return false;

    }

    return true;
  }

  return (
    // <Tooltip content="" relationship="inaccessible">
    <Input
      readOnly={disabled}
      type="text"
      className={cn("border-2", twColorForInput(day.color), className)}
      key={day.id}
      {...register(`timesheet.${day.id}`, {
        onChange,
        onBlur,
        validate: {
          validateHours: validateHours,
          minHoursPerDay: minHoursPerDay,
          maxHoursPerDay: maxHoursPerDay,
        },
      })}
      defaultValue={formatNumber(day.hours)}
    />
    // </Tooltip>
  );
};

export { TableInput };

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background font-semibold px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-center",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

const twColorForInput = (color: DayColor) => {
  switch (color) {
    case "green":
      return "bg-green-A300 border-green-A300 dark:text-black";
    case "yellow":
      return "bg-yellow-A300 border-yellow-A300 dark:text-black";
    case "red":
      return "bg-red-A300 border-red-A300 dark:text-black";
    case "white":
      return "bg-white border-gray-300 dark:bg-gray-300 dark:border-gray-300 dark:text-black helper-input-bg-white";
    case "slate":
      return "bg-slate-100 border-gray-300 dark:bg-slate-500 dark:border-slate-500 dark:text-black helper-input-bg-slate";

    default:
      return "bg-transparent";
  }
};

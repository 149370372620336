import React from "react";
import { TableCell, TableRow } from "../ui/table";
import clsx from "clsx";
import { useTableCollapsibleContext } from "./table-collapsible-context";
import { CaretDown20Regular, CaretUp20Regular } from "@fluentui/react-icons";

export const TableRowTrigger = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, children, ...props }, ref) => {
  const { open, onOpenToggle } = useTableCollapsibleContext();

  const selectIcon = () => {
    if (open) {
      return <CaretUp20Regular className="dark:text-gray-300 helper-text-black" />;
    }
    return <CaretDown20Regular className="dark:text-gray-300 helper-text-black" />;
  };

  return (
    <TableRow
      className={clsx("bg-white dark:bg-neutral-800 shadow-xl", className)}
      ref={ref}
      {...props}
    >
      <TableCell className="py-4 pl-2 pr-0">
        <button className="cursor-pointer w-full" onClick={onOpenToggle} type="button">
          {selectIcon()}
        </button>
      </TableCell>
      <TableCell colSpan={10} className="py-4 font-semibold text-slate-700 dark:text-slate-300 helper-text-black">
        <button onClick={onOpenToggle} className="cursor-pointer" type="button">
          {children}
        </button>
      </TableCell>
    </TableRow>
  );
});

import { Outlet, useLocation } from "react-router-dom";
import SidebarDesktopTimesheets, {
  sidebarData,
} from "../components/sidebar/sidebar-desktop-timesheets";
import { Text, makeStyles, typographyStyles } from "@fluentui/react-components";
import { Footer } from "../components/ui/footer";
import undraw_upload from "../assets/images/undraw_upload.png";
import { useEffect, useState } from "react";
import { cn } from "../lib/utils";
import { useMediaQuery } from "usehooks-ts";
import { TABLE_MEDIA_QUERY } from "../lib/constants";
import ForbiddenImport from "../pages/forbidden-import";
import msk_cc_logo_dark from "../assets/images/microsoft-image-dark.png";
import msk_cc_logo_light from "../assets/images/microsoft-image-light.png";

const useStyles = makeStyles({
  title: typographyStyles.title2,
});

export default function LayoutDesktopTimesheets() {
  const styles = useStyles();
  let location = useLocation();
  const isTableOrMobile = useMediaQuery(TABLE_MEDIA_QUERY);
  const [currentPage, setCurrentPage] = useState("");

  console.log(document.documentElement.classList);
  

  useEffect(() => {
    const page = sidebarData
      .flatMap((item) => item.children)
      .find((item) => item.url === location.pathname);

    if (page !== undefined) {
      setCurrentPage(`${page.id}. ${page.label}`);
    }
  }, [location]);

  if (isTableOrMobile) {
    return <ForbiddenImport />;
  }

  return (
    <div className="grid min-h-screen grid-cols-6 gap-4 pt-4">
      <aside className="col-span-1 pl-4">
        <SidebarDesktopTimesheets />
      </aside>
      <main className="col-span-5 pr-4">
        <div className="flex relative justify-between items-start h-64">
          <Text as="h1" className={cn(styles.title, "")}>
            {currentPage}
          </Text>
          <div className="flex xl:absolute xl:top-2 xl:left-0 xl:right-0 justify-center items-center">
            <img src={undraw_upload} alt="something" className="w-auto h-52 hidden md:block" />
          </div>
          <img
            src={document.documentElement.classList.contains('dark') ? msk_cc_logo_dark : msk_cc_logo_light}
            alt="Memorial Sloan Kettering Cancer Center logo"
            className={document.documentElement.classList.contains('dark') ? "w-auto h-16 hidden dark:md:block mt-4 ml-auto" : "w-auto h-16 hidden md:block mt-4 ml-auto dark:hidden"}
          />
        </div>
        <Outlet />
      </main>

      <Footer className="col-span-full">
        <div className="flex max-w-6xl w-full justify-between items-center">
          <Footer.Copyright />
          <Footer.Brand />
        </div>
      </Footer>
    </div>
  );
}

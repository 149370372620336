import { Button } from "@fluentui/react-components";
import { cn, formatTextToDecimal } from "../../lib/utils";
import { TimesheetDay } from "../../models/Timesheet";
import { Checkmark16Regular, Add20Regular } from "@fluentui/react-icons";
import { DialogTextArea } from "../dialog/dialog-text-area";

type TableBox1Props = {
  day: TimesheetDay;
  className?: string;
  showZero?: boolean;
};
type TableBox2Props = {
  day1: TimesheetDay;
  day2: TimesheetDay;
  className?: string;
  showZero?: boolean;
};

const selectBg = (color?: string) => {
  if (color === "green") {
    return "bg-green-A300 border border-green-A200 text-slate-700 dark:text-black";
  }
  if (color === "yellow") {
    return "bg-yellow-A300 border border-yellow-A200 text-slate-700 dark:text-black";
  }
  if (color === "red") {
    return "bg-red-A300 border border-red-A200 text-slate-700 dark:text-black";
  }
  if (color === "slate") {
    return "bg-slate-100 border border-slate-A10 text-slate-700 dark:text-black";
  }
  if (color === "transparent") {
    return "bg-transparent text-slate-700 dark:text-white";
  }
  return "bg-white border dark:bg-neutral-400 dark:border-neutral-400 dark:text-black";
};

export function TableBox2({ day1, day2, className, showZero }: TableBox2Props) {
  if (day1 === undefined) day1 = { id: "", color: "transparent", hours: 0 };
  if (day2 === undefined) day2 = { id: "", color: "transparent", hours: 0 };
  return (
    <div className={cn("flex justify-center items-center h-9 w-full", className)}>
      {formatTextToDecimal(day1.hours.toString(), showZero)} /{" "}
      {formatTextToDecimal(day2.hours.toString(), showZero)}
    </div>
  );
}

export function TableBox1({ day, className, showZero }: TableBox1Props) {
  if (day === undefined) day = { id: "", color: "transparent", hours: 0 };
  return (
    <div
      className={cn("flex justify-center items-center h-9 w-full", selectBg(day.color), className)}
    >
      {formatTextToDecimal(day.hours.toString(), showZero)}
    </div>
  );
}

interface TableAction1Props {
  onApprove: () => void;
  onReject: (rejectMessage: string) => void;
}

export function TableAction1({ onApprove, onReject }: TableAction1Props) {
  return (
    <div className="flex gap-2 justify-center items-center">
      <Button icon={<Checkmark16Regular />} size="small" appearance="primary" onClick={onApprove} />
      <DialogTextArea
        openDialogChildrenElement={
          <Button icon={<Add20Regular className="rotate-45 pt-[1px]" />} size="small"></Button>
        }
        dialogActionButtonName="Reject"
        onDialogActionButton={onReject}
        title="Please provide the reason for rejecting this Timesheet Task"
      />
    </div>
  );
}

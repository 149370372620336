import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../table";
import { cn } from "../../../lib/utils";
import { TableBodyCollapsible } from "../../table-collapsible/table-body-collapsible";
import { TableRowTrigger } from "../../table-collapsible/table-row-trigger";
import { TableRowContent } from "../../table-collapsible/table-row-content";
import {
  TimesheetTable,
  TimesheetTableFooter,
  TimesheetTableHeader,
} from "../../../models/TimesheetTable";

interface TableDesktopProps {
  data: TimesheetTable[];
  dataHeader: TimesheetTableHeader[];
  dataFooter: TimesheetTableFooter[];
  resourceName?: string;
}

export const TableDesktop = ({ data, dataHeader, dataFooter, resourceName }: TableDesktopProps) => {
  return (
    <Table>
      <TableDesktopHeader data={dataHeader} />
      <TableDesktopBody data={data} />
      <TableDesktopFooter resourceName={resourceName} data={dataFooter} />
    </Table>
  );
};

interface TableDesktopHeaderProps {
  data: TimesheetTableHeader[];
}

export const TableDesktopHeader = ({ data }: TableDesktopHeaderProps) => {
  return (
    <TableHeader style={{ position: "sticky", top: 0 }} className="bg-gray-100 dark:bg-neutral-800">
      <TableRow>
        <TableHead className="w-7"></TableHead>
        <TableHead className=""></TableHead>
        {data.map((item) => (
          <TableHead
            style={{ position: "sticky", top: 0 }}
            className={cn("w-1/12 dark:text-slate-300", item.className)}
            key={item.id}
          >
            {item.node}
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
  );
};

interface TableDesktopBodyProps {
  data: TimesheetTable[];
}

export const TableDesktopBody = ({ data }: TableDesktopBodyProps) => {
  return (
    <>
      {data.map((invoices) => (
        <TableBodyCollapsible
          key={invoices.projectId}
          defaultOpen={invoices.projectId !== "9999999999"}
        >
          <TableRowTrigger>{invoices.projectFriendlyName}</TableRowTrigger>
          {invoices.children.map((task) => (
            <TableRowContent key={task.taskId}>
              <TableCell className="bg-white dark:bg-neutral-800"></TableCell>
              <TableCell className="pl-10 pr-6 py-4 font-semibold text-slate-700 dark:text-slate-300 helper-text-black">
                {task.taskName}
              </TableCell>

              {task.children.map((item) => (
                <TableCell className={cn("helper-text-black")} key={item.id}>{item.node}</TableCell>
              ))}
            </TableRowContent>
          ))}
        </TableBodyCollapsible>
      ))}
    </>
  );
};

interface TableDesktopFooterProps {
  data: TimesheetTableFooter[];
  resourceName?: string;
}

export const TableDesktopFooter = ({ data, resourceName }: TableDesktopFooterProps) => {
  return (
    <TableBody className="before:block before:h-6">
      <TableRow className="bg-gray-100 border-none dark:bg-neutral-800">
        <TableCell
          colSpan={2}
          className="pl-10 pr-6 py-4 font-semibold text-slate-700 dark:text-slate-300 helper-text-black"
        >
          Weekly Timesheet Total {resourceName ? "for " + resourceName : ""}
        </TableCell>
        {data.map((item) => (
          <TableCell className={cn("helper-text-black")} key={item.id}>{item.node}</TableCell>
        ))}
      </TableRow>
    </TableBody>
  );
};

import { Title2 } from "@fluentui/react-components";
import DayPicker from "../week-picker/DayPicker";
import { SelectUsers } from "../select-users/select-users";
import msk_cc_logo_dark from "../../assets/images/microsoft-image-dark.png";
import msk_cc_logo_light from "../../assets/images/microsoft-image-light.png";

export default function MainNav() {
  return (
    <section className="flex justify-between items-center">
      <div>
        <div className="flex items-center gap-2">
          <Title2>Weekly Timesheet</Title2>
        </div>
        <DayPicker />
      </div>
      <div className="flex justify-between items-center gap-2">
        <SelectUsers />
        <img
          src={msk_cc_logo_light}
          alt="Memorial Sloan Kettering Cancer Center logo"
          className="w-auto h-16 hidden md:block dark:hidden"
        />
        <img
          src={msk_cc_logo_dark}
          alt="Memorial Sloan Kettering Cancer Center logo"
          className="w-auto h-16 hidden dark:md:block"
        />
      </div>
    </section>
  );
}

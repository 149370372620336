import { Spinner as SpinnerComponent, SpinnerProps } from "@fluentui/react-components";
import { useSpinnerContext } from "./spinner-context";

export function Spinner(props: Partial<SpinnerProps>) {
  const { open } = useSpinnerContext();

  if (open) {
    return <SpinnerItem />;
  }

  return null;
}

export function SpinnerItem(props: Partial<SpinnerProps>) {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-gray-500/50 z-50">
      <div className="flex justify-center items-center h-full">
        <SpinnerComponent {...props} />
      </div>
    </div>
  );
}

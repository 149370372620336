import { ReactNode } from "react";
import clsx from "clsx";
import { useCollapsibleContext } from "./collapsible-context";
import { CaretDown20Regular, CaretUp20Regular } from "@fluentui/react-icons";

interface RowTriggerProps {
  className?: string;
  children: ReactNode;
}

export const CollapsibleTrigger = ({ className, children, ...props }: RowTriggerProps) => {
  const { open, onOpenToggle } = useCollapsibleContext();

  const selectIcon = () => {
    if (open) {
      return <CaretUp20Regular className="dark:text-gray-300" />;
    }
    return <CaretDown20Regular className="dark:text-gray-300" />;
  };

  return (
    <div className={clsx("bg-white dark:bg-neutral-800 shadow-xl", className)} {...props}>
      <button
        onClick={onOpenToggle}
        className="cursor-pointer flex items-center gap-3"
        type="button"
      >
        <span className="py-4 pl-2 pr-0">{selectIcon()}</span>
        {children}
      </button>
    </div>
  );
};

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { DayColor } from "../models/Timesheet";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const groupBy = <T extends object, K extends keyof T>(array: T[], key: K) => {
  return array.reduce<Record<string, T[]>>((rv: any, currentValue: T) => {
      (rv[currentValue[key]] = rv[currentValue[key]] || []).push(currentValue);
    return rv;
  }, {});
};

export const sum = (dates: (number | undefined | null | string)[]) => {
  const date = dates.reduce((acc: number = 0, curr) => {
    const value = safeStringToFloat(curr);

    return value ? acc + value : acc;
  }, 0);
  const roundedDate = Math.round(date * 100) / 100;
  return roundedDate;
};

export function safeStringToInteger(input: any) {
  const convertedInput = parseInt(input);
  if (isNaN(convertedInput)) {
    return 0;
  } else {
    return convertedInput;
  }
}

export function safeStringToFloat(input: any) {
  const convertedInput = parseFloat(input);
  if (isNaN(convertedInput)) {
    return 0;
  } else {
    return convertedInput;
  }
}

export const convertColor = (color: string | undefined | null): DayColor => {
  if (color === undefined || color === null || color === "transparent") {
    return "transparent";
  }

  if (color === "green") {
    return color;
  }
  if (color === "yellow") {
    return color;
  }
  if (color === "red") {
    return color;
  }
  if (color === "slate") {
    return color;
  }
  return "white";
};

export const formatTextToDecimal = (value: string, showZero: boolean = false) => {
  const number = Number(value);

  if (Number.isNaN(number) || (showZero ? number < 0 : number <= 0)) {
    return "";
  }

  if (value.indexOf(".") < 0) {
    value += ".00";
  } else {
    const decimalLenght = value.split(".")[1].length;

    if (decimalLenght === 0) {
      value += "00";
    }
    if (decimalLenght === 1) {
      value += "0";
    }
    if (decimalLenght > 2) {
      value = value.slice(0, value.indexOf(".") + 3);
    }
  }

  return value;
};

export function roundToNearestQuarter(number: number): number {
  // Round the number to the nearest 0.25
  return Math.round(number * 4) / 4;
}

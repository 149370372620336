import { Input, InputProps, InputOnChangeData } from "@fluentui/react-components";
import { Search20Regular } from "@fluentui/react-icons";
import { useFilterContext } from "./filter-context";
import { ChangeEvent } from "react";
import { cn } from "../../lib/utils";

export function FilterTasks({ className, value: defaultValue, onChange, ...props }: InputProps) {
  const { selectedTasks } = useFilterContext();
  const [value, setSelectedValue] = selectedTasks;

  const handleFindTask = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setSelectedValue(data.value);
  };

  return (
    <Input
      appearance="underline"
      placeholder="Find a Task"
      value={value}
      onChange={handleFindTask}
      contentBefore={<Search20Regular />}
      className={cn("", className)}
      {...props}
    />
  );
}

import { createContext, useContext } from "react";

type WeekPickerContextValue = {
  firstDay: string;
  lastDay: string;
  changeWeek(date: Date): void;
};

const TableWeekPickerContext = createContext<WeekPickerContextValue | null>(null);

export function useTableWeekPickerContext() {
  const context = useContext(TableWeekPickerContext);

  if (!context) {
    throw new Error(
      "table-week-picker.* component must be rendered as child of table-week-picker component"
    );
  }

  return context;
}

export default TableWeekPickerContext;

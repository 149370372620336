import { useCallback, useState } from "react";

export function useToggle(defaultValue?: boolean): [boolean, () => void, (value: boolean) => void] {
  const [value, setValue] = useState(!!defaultValue);

  const toggle = useCallback(() => setValue((x) => !x), []);

  const handleChangeValue = (value: boolean) => {
    setValue(value);
  };

  return [value, toggle, handleChangeValue];
}

import { createContext, useContext } from "react";

type CollapsibleContextValue = {
  contentId: string;
  disabled?: boolean;
  open: boolean;
  onOpenToggle: () => void;
};

const TableCollapsibleContext = createContext<CollapsibleContextValue | null>(null);

export function useTableCollapsibleContext() {
  const context = useContext(TableCollapsibleContext);

  if (!context) {
    throw new Error(
      "table-collapsible.* component must be rendered as child of table-collapsible component"
    );
  }

  return context;
}

export default TableCollapsibleContext;

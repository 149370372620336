import { Dropdown, Option, DropdownProps } from "@fluentui/react-components";

export interface SelectProps extends Partial<DropdownProps> {
  options: any[];
}

export function Select<T>(props: SelectProps) {
  return (
    <Dropdown appearance="underline" expandIcon={null} multiselect {...props}>
      {props.options.map((option) => (
        <Option key={option}>{option}</Option>
      ))}
    </Dropdown>
  );
}

import { Select } from "../select/select";
import { useFilterContext } from "./filter-context";
import { cn } from "../../lib/utils";

interface FilterUsersProps {
  className?: string;
  options: string[];
}

export function FilterUsers({ className, options, ...props }: FilterUsersProps) {
  const { selectedUsers } = useFilterContext();
  const [value, setSelectedValue] = selectedUsers;

  const onOptionSelect = (event: any, data: any) => {
    setSelectedValue(data.selectedOptions);
  };

  return (
    <Select
      placeholder="Select User"
      options={options}
      selectedOptions={value}
      onOptionSelect={onOptionSelect}
      className={cn("", className)}
    />
  );
}

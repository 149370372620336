import { Timesheet, TimesheetResponse } from "../../models/Timesheet";
import { TimesheetTableFooter, TimesheetTableHeader } from "../../models/TimesheetTable";
import TableTimesheet from "../ui/table/table-timesheet";

interface TimesheetTableProps {
  data: Timesheet[];
  response: TimesheetResponse[];
  getRowsForHeader: (data: TimesheetResponse[]) => TimesheetTableHeader[];
  getRowsForFooter: (data: TimesheetResponse[]) => TimesheetTableFooter[];
}

export default function TimesheetTable({
  data,
  response,
  getRowsForHeader,
  getRowsForFooter,
}: TimesheetTableProps) {
  return (
    <TableTimesheet<TimesheetResponse>
      data={data}
      response={response}
      getRowsForHeader={getRowsForHeader}
      getRowsForFooter={getRowsForFooter}
    />
  );
}

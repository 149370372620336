import { useMediaQuery } from "usehooks-ts";
import { TABLE_MEDIA_QUERY } from "../../../lib/constants";
import { TableDesktop } from "./tableDesktop";
import { TableMobile } from "./tableMobile";
import { useMemo } from "react";
import {
  TimesheetTable,
  TimesheetTableFooter,
  TimesheetTableHeader,
} from "../../../models/TimesheetTable";

interface TableTimesheetProps<T> {
  data: TimesheetTable[];
  response: T[];
  resourceId?: string;
  resourceName?: string;
  getRowsForHeader: (data: T[]) => TimesheetTableHeader[];
  getRowsForFooter: (data: T[], resourceId?:string) => TimesheetTableFooter[];
}

export default function TableTimesheet<T>({
  data,
  response,
  resourceId,
  resourceName,
  getRowsForHeader,
  getRowsForFooter,
}: TableTimesheetProps<T>) {
  const isMatches = useMediaQuery(TABLE_MEDIA_QUERY);

  const dataHeader = useMemo(() => getRowsForHeader(response), [response]);
  const dataFooter = useMemo(() => getRowsForFooter(response, resourceId), [response]);

  if (isMatches) {
    return <TableMobile data={data} dataHeader={dataHeader} dataFooter={dataFooter} />;
  }
  return <TableDesktop data={data} dataHeader={dataHeader} dataFooter={dataFooter} resourceName={resourceName} />;
}


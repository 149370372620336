import React from "react";
import { cn } from "../../../lib/utils";
import { SidebarContext } from "./SidebarContext";

export interface SidebarProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
}

const SidebarComponent = React.forwardRef<HTMLDivElement, SidebarProps>(
  ({ className, children, isOpen = false, ...props }, ref) => (
    <SidebarContext.Provider value={{ isOpen }}>
      <div
        ref={ref}
        aria-label="Sidebar"
        className={cn("", className)}
        {...props}
      >
        <div className="">{children}</div>
      </div>
    </SidebarContext.Provider>
  )
);

export default SidebarComponent;

import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { authFetch } from "../api/fetchClient";

import * as microsoftTeams from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";

import { TeamsFxContext } from "../contexts/Context";
import config from "../lib/config";
import Router from "../router/router";
import { useLocation, useNavigate } from "react-router-dom";
import { UserData } from "../models/UserData";
import { useSessionStorage } from "usehooks-ts";
import { Spinner } from "../components/spinner/spinner";
import SpinnerContext from "../components/spinner/spinner-context";
import { useToggle } from "../hooks/useToggle";
import TableInputContext from "../components/ui/table/table-input-context";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackComponent from "../components/errors/ErrorFallbackComponent";
import { ApiErrorCode, isApiError } from "../models/ApiError";
import { Toaster } from "../components/ui/toast/toaster";
import { SelectUserProvider } from "../components/select-users/select-user-context";
import { ValuesFromFormContextProvider } from "../contexts/ValuesFromFormContext";
import { ValuesForFormTimesheetContextProvider } from "../contexts/ValuesForFormTimesheetContext";
import {BASE_URL} from "../models/envUrl";

// !INFO TEST MSK CICD

const themeClassName = (theme: string) => {
  document.documentElement.classList.remove("dark");
  switch (theme) {
    case "dark": {
      document.documentElement.classList.add("dark");
      return teamsDarkTheme;
    }
    case "contrast": {
      return teamsHighContrastTheme;
    }

    default:
      return {
        ...teamsLightTheme,
        colorNeutralBackground3: "#fff",
      };
  }
};

export default function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useSessionStorage<UserData>("user", {
    isPM: false,
    isR: false,
    isRM: false,
    isAdmin: false,
    resourceId: null,
    maxHoursPerDay: 0,
  });

  const { theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  let navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (loading) {
      microsoftTeams.app
        .initialize()
        .then(async () => {
          try {
            const userDataResponse = await authFetch<UserData>(
              `Resources/is-authorized?locationPath=${location.pathname}`,
              {
                method: "GET",
              }
            );
            setUser(userDataResponse);
          } catch (error) {
            if (isApiError(error)) {
              if (error.errorCode === ApiErrorCode.Unauthorized) navigate("/not-authorised");
              if (error.errorCode === ApiErrorCode.Forbidden) navigate("/forbidden");
              if (error.errorCode === ApiErrorCode.ForbiddenPM) navigate("/forbiddenPM");
              if (error.errorCode === ApiErrorCode.ForbiddenRM) navigate("/forbiddenRM");
            } else {
              navigate("/default-error");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          navigate("/default-error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  useEffect(() => {
    console.log("process.env.NODE_ENV",process.env.NODE_ENV)
    console.log("process.env.TAB_ENDPOINT", process.env.TAB_ENDPOINT);
    console.log("process.env.TAB_DOMAIN", process.env.TAB_DOMAIN);
    console.log(BASE_URL);
  }, [])
  

  const [open, t, handleOpen] = useToggle();
  const [disabled, t1, handleDisabled] = useToggle();

  const logError = (error: Error, info: { componentStack: string }) => {
    console.error("---CAUGHT AN ERROR---", error, info);
  };

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={themeClassName(themeString)}
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={logError}>
          <Toaster />
          <SpinnerContext.Provider value={{ open, onOpenToggle: handleOpen }}>
            <TableInputContext.Provider value={{ disabled, changeDisabled: handleDisabled }}>
              <ValuesFromFormContextProvider>
                <ValuesForFormTimesheetContextProvider>
                  <SelectUserProvider>
                    <Spinner />
                    {!loading && <Router />}
                  </SelectUserProvider>
                </ValuesForFormTimesheetContextProvider>
              </ValuesFromFormContextProvider>
            </TableInputContext.Provider>
          </SpinnerContext.Provider>
        </ErrorBoundary>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}

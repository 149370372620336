import { Select } from "../select/select";
import { useFilterContext } from "./filter-context";
import { cn } from "../../lib/utils";

interface FilterProjectProps {
  className?: string;
  options: string[];
}

export function FilterProjects({ className, options, ...props }: FilterProjectProps) {
  const { selectedProjects } = useFilterContext();
  const [value, setSelectedValue] = selectedProjects;

  const onOptionSelect = (event: any, data: any) => {
    setSelectedValue(data.selectedOptions);
  };

  return (
    <Select
      placeholder="Select Project"
      options={options}
      selectedOptions={value}
      onOptionSelect={onOptionSelect}
      className={cn("", className)}
    />
  );
}

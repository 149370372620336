import { ReactNode } from "react";
import { cn } from "../../../lib/utils";
import { useTableCollapsibleContext } from "../table-collapsible-context";

interface TableMobileRowContentProps {
  children: ReactNode;
  className?: string;
}

export const TableMobileRowContent = ({ className, children }: TableMobileRowContentProps) => {
  const { open } = useTableCollapsibleContext();



  return <div className={cn("flex flex-col", className, !open && "hidden")}>{children}</div>;
};

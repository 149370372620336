import { Button } from "@fluentui/react-components";
import { TimesheetByPM, TimesheetByPMResponse } from "../../models/TimesheetByPM";
import { TimesheetTableFooter, TimesheetTableHeader } from "../../models/TimesheetTable";
import { CollapsibleProvider } from "../collapsible/collapsible-context";
import { CollapsibleContent } from "../collapsible/row-content";
import { CollapsibleTrigger } from "../collapsible/row-trigger";
import TableTimesheet from "../ui/table/table-timesheet";

interface TimesheetByProjectManagerTableProps {
  data: TimesheetByPM[];
  response: TimesheetByPMResponse[];
  getRowsForHeader: (data: TimesheetByPMResponse[]) => TimesheetTableHeader[];
  getRowsForFooter: (data: TimesheetByPMResponse[]) => TimesheetTableFooter[];
  handleCommand: (resourceId: string) => void;
}

export default function TimesheetByProjectManagerTable({
  data,
  response,
  getRowsForHeader,
  getRowsForFooter,
  handleCommand,
}: TimesheetByProjectManagerTableProps) {
  if (data !== undefined) {
    console.log(data, "dataMap");
    return (
      <div>
        {data.map((resources) => (
          <CollapsibleProvider key={resources.resourceId}>
            <div className="mb-5">
              <CollapsibleTrigger>
                <h3 className="text-lg font-semibold text-slate-700 dark:text-slate-100 helper-text-black">{resources.resourceName}</h3>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <TableTimesheet<TimesheetByPMResponse>
                  data={resources.children}
                  response={response}
                  resourceId={resources.resourceId}
                  resourceName={resources.resourceName}
                  getRowsForHeader={getRowsForHeader}
                  getRowsForFooter={getRowsForFooter}
                />
              </CollapsibleContent>
              <div className="flex justify-end items-center mt-4">
                <Button appearance="primary" onClick={() => handleCommand(resources.resourceId)}>
                  Approve All
                </Button>
              </div>
            </div>
          </CollapsibleProvider>
        ))}
      </div>
    );
  }

  return <></>;
}

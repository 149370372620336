import { TABLE_MEDIA_QUERY } from "../../lib/constants";
import { useMediaQuery } from "usehooks-ts";
import MainNav from "../main-nav/main-nav";
import MobileNav from "../mobile-nav/mobile-nav";

export function PageHeader() {
  const isTableOrMobile = useMediaQuery(TABLE_MEDIA_QUERY);

  if (isTableOrMobile) {
    return <MobileNav />;
  }

  return <MainNav />;
}

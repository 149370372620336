import { Outlet } from "react-router-dom";
import { PageHeader } from "../components/page-header/page-header";
import { Footer } from "../components/ui/footer";
import TableWeekPickerContext from "../components/week-picker/table-week-picker-context";
import { ReactNode, useState } from "react";
import dayjs from "dayjs";
import { formatShortDate } from "../lib/date";
import FiltersContext from "../components/filters/filters-context";

export default function Layout() {
  const [filterNodes, setFilterNodes] = useState<ReactNode>(<></>);
  const handleChangeFilter = (node: ReactNode) => {
    setFilterNodes(node);
  };

  const [firstDay, setFirstDay] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleChangeWeek = (currentDate: Date) => {
    const date = dayjs(currentDate);

    setFirstDay(formatShortDate(date.startOf("week").toDate()));
    setLastDay(formatShortDate(date.endOf("week").toDate()));
  };

  return (
    <FiltersContext.Provider
      value={{ filterNodes, setFilters: handleChangeFilter, showFilter, setShowFilter }}
    >
      <TableWeekPickerContext.Provider value={{ firstDay, lastDay, changeWeek: handleChangeWeek }}>
        <div className="relative flex min-h-screen flex-col p-5">
          <PageHeader />
          <main className="flex-1">
            <Outlet />
          </main>

          <Footer className="col-span-full">
            <div className="flex max-w-6xl w-full justify-between items-center">
              <Footer.Copyright />
              <Footer.Brand />
            </div>
          </Footer>
        </div>
      </TableWeekPickerContext.Provider>
    </FiltersContext.Provider>
  );
}

import { ArrowLeft16Filled, ArrowRight16Filled, Home16Regular } from "@fluentui/react-icons";
import { WeekCalendar } from "../ui/calendar/WeekCalendar";
import { useCallback, useEffect, useState } from "react";
import { addWeeks } from "@fluentui/react-datepicker-compat";
import { useMediaQuery } from "usehooks-ts";
import { TABLE_MEDIA_QUERY } from "../../lib/constants";
import { useTableWeekPickerContext } from "./table-week-picker-context";

export interface DayPickerProps {}

export default function DayPicker() {
  const isTableOrMobile = useMediaQuery(TABLE_MEDIA_QUERY);

  const { changeWeek } = useTableWeekPickerContext();

  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(new Date());

  const goPrevious = useCallback(() => {
    setSelectedDate((prevSelectedDate) =>
      prevSelectedDate ? addWeeks(prevSelectedDate, -1) : null
    );
  }, []);

  const goNext = useCallback(() => {
    setSelectedDate((prevSelectedDate) =>
      prevSelectedDate ? addWeeks(prevSelectedDate, 1) : null
    );
  }, []);

  const goToToday = useCallback(() => {
    setSelectedDate(new Date());
  }, []);

  useEffect(() => {
    if (selectedDate === undefined || selectedDate === null) {
      return;
    }
    changeWeek(selectedDate);
  }, [selectedDate]);

  return (
    <div className="flex items-center gap-4">
      {isTableOrMobile ? (
        <WeekCalendar
          contentAfter={null}
          appearance="filled-lighter"
          value={selectedDate}
          onSelectDate={setSelectedDate}
        />
      ) : (
        <>
          <WeekCalendar value={selectedDate} onSelectDate={setSelectedDate} />
          <button className="flex" onClick={goPrevious}>
            <ArrowLeft16Filled />
          </button>
          <button className="flex" onClick={goNext}>
            <ArrowRight16Filled />
          </button>
          <button className="flex" onClick={goToToday}>
            <Home16Regular />
          </button>
        </>
      )}
    </div>
  );
}

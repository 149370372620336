import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed } from "powerbi-client";
import { useState } from "react";
import { useApi } from "../hooks/useApi";
import { ReportEmbedConfig } from "../models/ReportEmbedConfig";
import { SpinnerItem } from "../components/spinner/spinner";
import ForbiddenImport from "./forbidden-import";
import { TABLE_MEDIA_QUERY } from "../lib/constants";
import { useMediaQuery } from "usehooks-ts";

export default function PowerBiReport() {
  const isTableOrMobile = useMediaQuery(TABLE_MEDIA_QUERY);
  const [report, setReport] = useState<Report>();
  const [response] = useApi<ReportEmbedConfig>(`/v1/Reports`);

  if (isTableOrMobile) {
    return <ForbiddenImport />;
  }
  if (response.error) {
    return null;
  }
  if (!response.data) {
    return <SpinnerItem />;
  }

  return (
    <section className="w-screen h-screen flex">
      <section id="bi-report" className="w-full h-full">
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: response.data.embedReports[0].reportId,
            embedUrl: response.data.embedReports[0].embedUrl,
            accessToken: response.data.embedToken.token,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: true,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              // ['loaded', function () {console.log('Report loaded');}],
              // ['rendered', function () {console.log('Report rendered');}],
              [
                "error",
                function (event) {
                  console.log(event);
                },
              ],
              // ['visualClicked', () => console.log('visual clicked')],
              // ['pageChanged', (event) => console.log(event)],
            ])
          }
          cssClassName={"bi-embedded"}
          getEmbeddedComponent={(embeddedReport) => {
            setReport(embeddedReport as Report);
          }}
        />
      </section>
    </section>
  );
}

import { Timesheet } from "../../models/Timesheet";
import { Filter, FilterProjects, FilterTasks } from "../filter/filter";

interface TimesheetFilterProps {
  modifyResponse: Timesheet[];
  setVisibleProjects: React.Dispatch<React.SetStateAction<Timesheet[]>>;
  optionsProjects: string[];
}

export default function TimesheetFilter({
  modifyResponse,
  setVisibleProjects,
  optionsProjects,
}: TimesheetFilterProps) {
  return (
    <Filter
      response={modifyResponse}
      returnVisibleProjects={setVisibleProjects}
      className="flex flex-col sm:flex-row gap-5"
    >
      <FilterProjects options={optionsProjects} className="w-full sm:w-1/5" />
      <FilterTasks className="w-full sm:w-4/5" />
    </Filter>
  );
}

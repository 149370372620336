import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
import { ApiError, ApiErrorCode } from "../models/ApiError";
import {BASE_URL} from "../models/envUrl";


const instance: AxiosInstance = axios.create({
  baseURL: `https://${BASE_URL}/api`,
  timeout: 600000,
  withCredentials: true,
  method: "GET",
});

instance.interceptors.request.use(async (config) => {
  const token = await microsoftTeams.authentication.getAuthToken().catch((err) => {
    const AuthError: ApiError = {
      errorCode: ApiErrorCode.Unauthorized,
      title: "Unauthorized Error",
      message: `Unable to get Auth token ${err}`,
      time: Date.now().toString(),
    };
    throw AuthError;
  });
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});



const api = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  try {
    const { data } = await instance.request<T>({ url, ...config });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // Server was able to send us a response, so this is an API Error.
        console.error("[API Error]:", error.response);
        throw error.response.data;
      } else {
        // Axios was not able to get a response at all. This is a Network-Level Error.
        console.error("[Network Error]: No Response Received At", url);
        const networkError: ApiError = {
          errorCode: ApiErrorCode.Unknown,
          title: "Network Error",
          message: "No Response Received",
          time: Date.now().toString(),
        };
        throw networkError;
      }
    }
    console.error("[Non-HTTP Error]:", error);
    // Standard JS Error (Syntax, etc...)
    throw error;
  }
};

export default api;

/*
1) ishendlovane koje su namerno opaljene na bekendu prikazuje se toast
2) Greske koje je uhvatio midleware (npr pukao je entity framework) uhvatice ih midleware i imas isti model APiError sa statusom Unknown | internalServerError, tu treba da redirektuje na stranicu sa defualtnom errorom ona struja i ispis something went wrong nesto
3) Greske koje nije uhvatio midleware nisu stigle do bekenda ili ih je sprecio pre midleware to moze da bude npr 404, kad se stavi pogresno ime endpointa ili nema interneta

*/

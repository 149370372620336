import { Subtitle1, Subtitle2 } from "@fluentui/react-components";
import TestSVG from "../assets/svg/TestSVG";

export default function ForbiddenPM() {
  return (
    <main className="flex flex-col justify-center items-center h-screen w-screen gap-4">
      <TestSVG />
      <Subtitle1 as="h1">You are not authorized to access this page</Subtitle1>
      <Subtitle2 as="h3" className="mb-12">
        Access to this page is restricted to project managers/administrators only, and your current
        permissions do not grant you the necessary access rights.
      </Subtitle2>
    </main>
  );
}

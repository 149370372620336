import React from "react";
import { TableBody } from "../ui/table";
import { cn } from "../../lib/utils";
import TableCollapsibleContext from "./table-collapsible-context";
import { useToggle } from "../../hooks/useToggle";

interface TableBodyCollapsibleProps extends React.HTMLAttributes<HTMLTableSectionElement> {
  defaultOpen?: boolean;
}

export const TableBodyCollapsible = React.forwardRef<
  HTMLTableSectionElement,
  TableBodyCollapsibleProps
>(({ className, defaultOpen = true, ...props }, ref) => {
  const [isOpen, toggleOpen] = useToggle(defaultOpen);

  return (
    <TableCollapsibleContext.Provider
      value={{
        open: isOpen,
        onOpenToggle: toggleOpen,
        contentId: "",
      }}
    >
      <TableBody
        ref={ref}
        className={cn("[&_tr:last-child]:border-0 before:block before:h-3", className)}
        {...props}
      />
    </TableCollapsibleContext.Provider>
  );
});

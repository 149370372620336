import React from "react";
import { TableRow } from "../ui/table";
import { cn } from "../../lib/utils";
import { useTableCollapsibleContext } from "./table-collapsible-context";

export const TableRowContent = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => {
  const { open } = useTableCollapsibleContext();

  return (
    <TableRow
      className={cn("bg-gray-100 dark:bg-neutral-800 border-none", className, !open && "hidden")}
      ref={ref}
      {...props}
    />
  );
});

import { Icons } from "../../Icons";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./toast";

import { useToast } from "./use-toast";

function variantIcon(variant: any) {
  switch (variant) {
    case "info": {
      return <Icons.info />;
    }
    case "success": {
      return <Icons.success />;
    }
    case "warning": {
      return <Icons.warning />;
    }
    case "error": {
      return <Icons.error />;
    }

    default: {
      return <></>;
    }
  }
}

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex items-start gap-2">
              <div className="mt-1">{variantIcon(props.variant)}</div>
              <div className="grid gap-1">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && <ToastDescription>{description}</ToastDescription>}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

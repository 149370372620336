import FooterComponent from "./Footer";
import FooterCopyright from "./FooterCopyright";
import FooterBrand from "./FooterBrand";

export type { FooterProps } from "./Footer";

export const Footer = Object.assign(FooterComponent, {
  Copyright: FooterCopyright,
  Brand: FooterBrand,
});

import { createContext, useContext } from "react";

type TableInputContextValue = {
  disabled: boolean;
  changeDisabled(isDisabled: boolean): void;
};

const TableInputContext = createContext<TableInputContextValue | null>(null);

export function useTableInputContext() {
  const context = useContext(TableInputContext);

  if (!context) {
    throw new Error(
      "table-input-component.* component must be rendered as child of table-input-component component"
    );
  }

  return context;
}

export default TableInputContext;

import { useSessionStorage } from "usehooks-ts";
import { UserData } from "../../models/UserData";
import {
  Button,
  DropdownProps,
  Input,
  InputProps,
  Menu,
  MenuPopover,
  MenuProps,
  MenuTrigger,
} from "@fluentui/react-components";
import { useEffect, useMemo, useState } from "react";
import { Icons } from "../Icons";
import { useApi } from "../../hooks/useApi";
import { useSelectUserContext } from "./select-user-context";
import { useLocation } from "react-router-dom";

export interface SelectUserByAdmin {
  resourceId: string;
  fullName: string;
  workEmail: string;
}

export const initSelectUserByAdmin = {
  resourceId: "",
  fullName: "",
  workEmail: "",
};

interface SelectUsersProps extends Partial<DropdownProps> {}

export function SelectUsers({ className, ...props }: SelectUsersProps) {
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [userShow, setUserShow] = useState("");
  const [selectedUser, setSelectedUser] = useSessionStorage<SelectUserByAdmin>(
    "select-user-by-admin",
    initSelectUserByAdmin
  );
  const [currentUser] = useSessionStorage<UserData>("user", {
    isPM: false,
    isR: false,
    isRM: false,
    isAdmin: false,
    resourceId: null,
    maxHoursPerDay: 0,
  });
  const { url, refresh: selectRefresh } = useSelectUserContext();

  const [options, refresh] = useApi<SelectUserByAdmin[]>(url.url);

  useEffect(() => {
    function modifyRefresh() {
      handleCleanUser();
      refresh();
    }

    selectRefresh.current = modifyRefresh;
  }, []);

  const visibleOptions = useMemo(() => {
    if (!options || !options.data) {
      return [];
    }
    if (!user) {
      return options.data;
    }
    const userLowerCase = user.toLowerCase();
    return options.data.filter((option: any) =>
      option.fullName.toLowerCase().includes(userLowerCase)
    );
  }, [user, options]);

  const handleModel: MenuProps["onOpenChange"] = (event, data) => {
    setOpen(data.open);
  };

  const handleChange: InputProps["onChange"] = (event, data) => {
    setUser(data.value);
  };

  const selectUser = (option: any) => {
    setUserShow(option.fullName);
    setOpen(false);
    setUser("");
    setSelectedUser({
      resourceId: option.resourceId,
      fullName: option.fullName,
      workEmail: option.workEmail,
    });
  };

  useEffect(() => {
    if (selectedUser.fullName) {
      setUserShow(selectedUser.fullName);
    }
  }, []);

  const handleCleanUser = () => {
    setOpen(false);
    setUser("");
    setUserShow("");
    setSelectedUser(initSelectUserByAdmin);
  };

  useEffect(() => {
    handleCleanUser();
  }, [location]);

  if (!currentUser.isAdmin) return null;

  return (
    <>
      <Menu open={open} onOpenChange={handleModel}>
        <MenuTrigger disableButtonEnhancement>
          <Button className="min-w-fit">
            {userShow ? `Working on behalf of: ${userShow}` : "Work on behalf of?"}
          </Button>
        </MenuTrigger>
        <MenuPopover className="max-h-96 !min-w-[460px]  overflow-y-scroll">
          <div className="p-2">
            <Input value={user} onChange={handleChange} className="w-full" />
            <ul className="p-2">
              {visibleOptions.map((option: any) => (
                <button
                  key={option.resourceId}
                  className="block pb-1"
                  onClick={() => selectUser(option)}
                >
                  {option.fullName} ({option.workEmail})
                </button>
              ))}
            </ul>
          </div>
        </MenuPopover>
      </Menu>
      {userShow ? <Button icon={<Icons.close />} onClick={handleCleanUser}></Button> : null}
    </>
  );
}

export default function TestSVG() {
  return (
    <svg
      width="220"
      height="214"
      viewBox="0 0 220 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.747 154.179L55.8878 185.795C53.675 186.517 51.2765 185.374 50.5261 183.243L0.224502 40.1228C-0.52436 37.9902 0.661283 35.6787 2.87269 34.9557L99.7315 3.33941C101.944 2.61773 104.344 3.76021 105.093 5.89149L155.395 149.013C156.144 151.144 154.959 153.456 152.747 154.179Z"
        fill="#E1DFDD"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.2247 80.0065L61.9446 87.9323C53.0905 90.8221 43.4804 86.2483 40.4802 77.7129L34.3757 60.349C31.3785 51.8162 36.1245 42.5548 44.98 39.6636L69.2601 31.7392C78.1156 28.8481 87.7257 33.4218 90.7244 41.9572L96.8275 59.3213C99.8276 67.8552 95.0786 77.1154 86.2247 80.0065Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9923 63.5453C78.6956 63.9688 77.2891 63.2984 76.8498 62.0502L69.958 42.4415C69.5185 41.1906 70.214 39.8338 71.5094 39.4117C72.8075 38.9881 74.214 39.6585 74.6521 40.9082L81.5453 60.5167C81.9833 61.7664 81.2891 63.1218 79.9923 63.5453Z"
        fill="#C8C6C4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.082 147.885L85.8018 155.809C76.9464 158.701 67.3363 154.127 64.3375 145.591L58.233 128.228C55.2343 119.694 59.9819 110.433 68.8358 107.542L93.1159 99.6166C101.973 96.7266 111.583 101.3 114.58 109.835L120.685 127.2C123.685 135.734 118.936 144.994 110.082 147.885Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.5137 71.2102C55.217 71.6324 53.8104 70.962 53.3711 69.7137L46.4792 50.1052C46.0397 48.8543 46.7354 47.4987 48.032 47.0752C49.3287 46.6517 50.7365 47.3221 51.1746 48.5717L58.0666 68.1816C58.506 69.4299 57.8104 70.7867 56.5137 71.2102Z"
        fill="#C8C6C4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.4568 71.8906C72.8962 73.1402 72.2007 74.4956 70.9039 74.9191C69.6072 75.3426 68.1994 74.6737 67.7598 73.4227C67.3219 72.1744 68.016 70.8177 69.3142 70.3941C70.6094 69.9706 72.0172 70.641 72.4568 71.8906Z"
        fill="#C8C6C4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.3708 139.086C79.074 139.509 77.6675 138.839 77.2283 137.591L70.3364 117.982C69.8969 116.731 70.5924 115.374 71.8878 114.952C73.1859 114.529 74.5938 115.199 75.0305 116.449L81.9237 136.057C82.363 137.307 81.6673 138.662 80.3708 139.086Z"
        fill="#C8C6C4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.85 131.423C102.553 131.847 101.146 131.176 100.707 129.928L93.8137 110.318C93.3756 109.068 94.0712 107.712 95.3665 107.288C96.6632 106.865 98.071 107.535 98.5092 108.786L105.401 128.395C105.841 129.644 105.146 131 103.85 131.423Z"
        fill="#C8C6C4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.3128 139.769C96.7523 141.019 96.0567 142.374 94.7614 142.797C93.4633 143.22 92.0569 142.551 91.6174 141.301C91.1779 140.053 91.8735 138.696 93.1702 138.273C94.4668 137.849 95.8734 138.519 96.3128 139.769Z"
        fill="#C8C6C4"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.761 58.7637L163.845 41.9341C164.125 41.648 164.623 41.7597 164.744 42.1356L167.6 49.1842C167.705 49.5152 168.113 49.6534 168.41 49.4587L180.126 41.795C180.644 41.4548 181.24 42.1054 180.827 42.56L164.937 59.9632C164.658 60.2698 164.136 60.1565 164.024 59.7632L161.767 52.042C161.666 51.701 161.248 51.5584 160.945 51.762L149.456 59.5361C148.929 59.8937 148.32 59.2145 148.761 58.7637Z"
        fill="#C5CBFA"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.454 47.2253L134.662 16.028C134.655 15.4723 135.271 15.1171 135.775 15.3874L145.798 19.8257C146.24 20.0602 146.792 19.8165 146.894 19.3418L150.979 0.574503C151.16 -0.255756 152.422 -0.159158 152.463 0.690344L154.022 33.2267C154.05 33.798 153.409 34.171 152.895 33.8841L142.796 28.2027C142.35 27.9508 141.781 28.1991 141.682 28.6886L137.94 47.3547C137.769 48.2083 136.466 48.0958 136.454 47.2253Z"
        fill="#C5CBFA"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.993 82.8154C210.076 81.8325 206.885 83.3143 205.864 86.1255L194.776 116.669L205.34 120.231L216.428 89.6877C217.45 86.8765 215.912 83.7983 212.993 82.8154Z"
        fill="#E1DFDD"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.017 70.3491C173.1 69.3662 169.908 70.848 168.886 73.6592L157.8 104.203L168.363 107.765L179.452 77.2214C180.474 74.4103 178.935 71.332 176.017 70.3491Z"
        fill="#E1DFDD"
      ></path>
      <path
        d="M84.5767 213.85C84.3966 213.85 84.2145 213.843 84.0304 213.825C80.8936 213.539 78.5924 210.855 78.8908 207.832C80.6031 190.456 92.3412 178.824 108.128 178.824C108.194 178.824 108.261 178.824 108.328 178.824C116.616 178.875 122.011 183.311 126.348 186.877C130.739 190.488 134.202 193.33 140.422 192.863C151.809 191.988 155.722 170.859 155.758 170.645C156.268 167.652 159.185 165.614 162.304 166.105C165.411 166.591 167.523 169.405 167.023 172.401C166.819 173.618 161.807 202.251 141.328 203.824C130.403 204.667 124.047 199.44 118.941 195.242C114.749 191.795 112.187 189.844 108.255 189.82C108.215 189.82 108.176 189.82 108.136 189.82C98.4071 189.82 91.3923 197.284 90.25 208.872C89.9695 211.718 87.4842 213.85 84.5767 213.85Z"
        fill="#7F85F5"
      ></path>
      <path
        d="M157.85 178.394L157.479 178.27C135.683 170.967 124.158 148.019 131.736 127.014L140.458 102.838C141.243 100.662 143.71 99.5111 145.968 100.268L217.092 124.099C219.35 124.855 220.544 127.233 219.759 129.408L211.036 153.585C203.458 174.59 179.646 185.697 157.85 178.394Z"
        fill="#7F85F5"
      ></path>
    </svg>
  );
}

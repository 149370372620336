import { ReactNode, useState } from "react";
import FilterContext from "./filter-context";
import { FilterTasks } from "./filter-tasks";
import { FilterProjects } from "./filter-projects";
import { FilterUsers } from "./filter-users";
import { Timesheet } from "../../models/Timesheet";
import { TimesheetByPM } from "../../models/TimesheetByPM";
import { TimesheetByRM } from "../../models/TimesheetByRM";
import { cn } from "../../lib/utils";

interface FilterProps {
  response: Timesheet[] | TimesheetByPM[] | TimesheetByRM[];
  returnVisibleProjects: any;
  children: ReactNode;
  className?: string;
}

export function Filter({ response, returnVisibleProjects, children, className }: FilterProps) {
  const selectedProjects = useState<string[]>([]);
  const selectedTasks = useState<string>("");
  const selectedUsers = useState<string[]>([]);

  return (
    <section
      className={cn("w-full hidden justify-center items-center gap-2 lg:px-8 sm:flex", className)}
    >
      <FilterContext.Provider
        value={{ response, returnVisibleProjects, selectedProjects, selectedTasks, selectedUsers }}
      >
        {children}
      </FilterContext.Provider>
    </section>
  );
}

export { FilterTasks, FilterProjects, FilterUsers };

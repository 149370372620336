import { useRef } from "react";
import { Label, useId } from "@fluentui/react-components";
import DataImportTable from "../../components/tables/data-import-table";
import { DialogFulfillsFilename } from "../../components/dialog/dialog-fulfills-filename";
import { useTest1 } from "../../hooks/useTest1";
import { useSessionStorage } from "usehooks-ts";
import { UserData } from "../../models/UserData";

const PAGE_FILE_NAME = "worktypes";
const firstApi = "/v1/Imports/logs?tableName=WorkTypes&dateFrom=2023-01-01&dateTo=2028-12-31";
const secoundApi = "/v1/Imports/import-data?tableName=WorkTypes";

export default function WorkTypes() {
  const inputId = useId("inputFile");
  const [user] = useSessionStorage<UserData>("user", {
    isPM: false,
    isR: false,
    isRM: false,
    isAdmin: false,
    resourceId: null,
    maxHoursPerDay: 0,
  });
  const secoundApi = useRef<string>(
    `/v1/Imports/import-data?resourceId=${user.resourceId}&tableName=WorkTypes&priority=1`
  );

  const { response, isExcelFileValid, handleSubmit, handleFileChange } = useTest1({
    PAGE_FILE_NAME,
    firstApi,
    secoundApi: secoundApi.current,
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-start items-center gap-4 ">
        <Label htmlFor={inputId} weight="semibold" className="justify-self-start">
          Input file
        </Label>
        <form>
          <input type="file" onChange={handleFileChange} />
          <DialogFulfillsFilename handleUpload={handleSubmit} skipDialog={isExcelFileValid} />
        </form>
      </div>
      <DataImportTable data={response.data} />
    </div>
  );
}

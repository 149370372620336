import { TimesheetByRM } from "../../models/TimesheetByRM";
import { Filter, FilterProjects, FilterTasks, FilterUsers } from "../filter/filter";

interface TimesheetByRMFilterProps {
  modifyResponse: TimesheetByRM[];
  setVisibleProjects: React.Dispatch<React.SetStateAction<TimesheetByRM[]>>;
  optionsProjects: string[];
  optionsUsers: string[];
}

export default function TimesheetByRMFilter({
  modifyResponse,
  setVisibleProjects,
  optionsProjects,
  optionsUsers,
}: TimesheetByRMFilterProps) {
  return (
    <Filter
      response={modifyResponse}
      returnVisibleProjects={setVisibleProjects}
      className="flex flex-col sm:flex-row gap-5"
    >
      <FilterProjects options={optionsProjects} className="w-full sm:w-1/5" />
      <FilterUsers options={optionsUsers} className="w-full sm:w-1/5" />
      <FilterTasks className="w-full sm:w-4/5" />
    </Filter>
  );
}

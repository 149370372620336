import { ReactNode, createContext, useContext, useRef, useState } from "react";

interface SelectUserContext {
  url: { url: string; setUrl: React.Dispatch<React.SetStateAction<string>> };
  refresh: React.MutableRefObject<(() => void) | undefined>;
}

const SelectUserContext = createContext<SelectUserContext | null>(null);

export function useSelectUserContext() {
  const context = useContext(SelectUserContext);
  if (!context) {
    throw new Error("select-user.* component must be rendered as child of select-user component");
  }
  return context;
}

export function SelectUserProvider({ children }: { children: ReactNode }) {
  const [url, setUrl] = useState("");
  const refresh = useRef<() => void>();

  return (
    <SelectUserContext.Provider value={{ url: { url, setUrl }, refresh }}>
      {children}
    </SelectUserContext.Provider>
  );
}

export default SelectUserContext;

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/en";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 6,
});
dayjs.extend(weekOfYear);
dayjs.locale("en");

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const WEEKDAYS_SHORT = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const to2Digits = (num: string) => {
  return num.padStart(2, "0");
};

export const formatShortDate = (date: Date) => {
  const year = date.toLocaleString("en-US", { year: "numeric" });
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });

  return `${year}-${month}-${day}`;
};

export const formatShortDateUS = (date: Date) => {
  return new Intl.DateTimeFormat("en-US").format(date);
};

export const formatShortDateWithNamedMonth = (date: Date, withYear: boolean = true) => {
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const month = MONTHS_SHORT[date.getMonth()];
  const year = date.toLocaleString("en-US", { year: "numeric" });

  if (!withYear) {
    return `${day} ${month}`;
  }

  return `${day} ${month} ${year}`;
};

export const formatCompleteDate = (date: Date) => {
  const year = date.toLocaleString("en-US", { year: "numeric" });
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const hour = date.toLocaleString("en-US", { hour: "numeric", hour12: false });
  const minute = date.toLocaleString("en-US", { minute: "numeric" });
  const second = date.toLocaleString("en-US", { second: "numeric" });

  return `${year}-${month}-${day} ${to2Digits(hour)}:${to2Digits(minute)}:${to2Digits(second)}`;
};

export const formatCompleteDateUS = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    formatMatcher: "basic",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const selectedWeek = (selectedDate: Date) => {
  return dayjs(selectedDate).week();
};

export const getFirstAndLastDayOfWeek = (selectedDate: Date) => {
  const firstDayOfWeek = dayjs(selectedDate).startOf("week").toDate();
  const lastDayOfWeek = dayjs(selectedDate).endOf("week").toDate();

  return [firstDayOfWeek, lastDayOfWeek];
};

export const formatDatewithWeeks = (selectedDate: Date) => {
  const [firstDayOfWeek, lastDayOfWeek] = getFirstAndLastDayOfWeek(selectedDate);
  const week = selectedWeek(selectedDate);
  const fromDate = formatShortDateUS(firstDayOfWeek);
  const toDate = formatShortDateUS(lastDayOfWeek);

  return `Week ${week}, ${fromDate} - ${toDate}`;
};

export const formatDatewithWeeksForMobile = (selectedDate: Date) => {
  const [firstDayOfWeek, lastDayOfWeek] = getFirstAndLastDayOfWeek(selectedDate);

  const fromDate = formatShortDateWithNamedMonth(firstDayOfWeek, false);
  const toDate = formatShortDateWithNamedMonth(lastDayOfWeek);

  return `${fromDate} - ${toDate}`;
};

export const getCurentWeekday = (date: Date, short: boolean = false) => {
  if (date === undefined) return "";

  if (short) {
    return WEEKDAYS_SHORT[date.getDay()];
  }
  return WEEKDAYS[date.getDay()];
};

export const getCurentMonth = (date: Date, short: boolean = false) => {
  if (date === undefined) return "";
  if (short) {
    return MONTHS_SHORT[date.getMonth()];
  }
  return MONTHS[date.getMonth()];
};

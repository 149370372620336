import { Button, Text } from "@fluentui/react-components";
import { useMediaQuery, useSessionStorage } from "usehooks-ts";
import { useApi } from "../hooks/useApi";
import { useEffect, useMemo, useState, useRef } from "react";

import {
  TimesheetResponse,
  getProjectNames,
  fromTimesheetToTimesheetResponse,
  fromTimesheetResponseToTimesheet,
  getRowsForFooter,
  getRowsForHeader,
} from "../models/Timesheet";
import { DESKTOP_MEDIA_QUERY } from "../lib/constants";
import api from "../lib/apiBase";
import { useForm, useFieldArray } from "react-hook-form";
import { UserData } from "../models/UserData";
import { useTableWeekPickerContext } from "../components/week-picker/table-week-picker-context";
import { useSpinnerContext } from "../components/spinner/spinner-context";
import { useTableInputContext } from "../components/ui/table/table-input-context";
import { useToast } from "../components/ui/toast/use-toast";

import TimesheetTable from "../components/tables/timesheet-table";
import { useFiltersContext } from "../components/filters/filters-context";
import TimesheetFilter from "../components/filters/timesheet-filter";
import { DialogFulfillsRecall } from "../components/dialog/dialog-fulfills-recall";
import { isApiError } from "../models/ApiError";
import { SpinnerItem } from "../components/spinner/spinner";
import { Commands } from "../lib/command";
import { SelectUserByAdmin, initSelectUserByAdmin } from "../components/select-users/select-users";
import { useNavigate } from "react-router-dom";
import { useSelectUserContext } from "../components/select-users/select-user-context";
import { useValuesFromFormContext } from "../contexts/ValuesFromFormContext";
import { useValuesForFormTimesheetContext } from "../contexts/ValuesForFormTimesheetContext";

const PAGE_FILE_NAME = "Timesheet";

export default function Timesheet() {
  const navigate = useNavigate();
  const { values, setValues } = useValuesFromFormContext();
  const { values: valuesTimesheet, setValues: setValuesTimesheet } =
    useValuesForFormTimesheetContext();
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const lastCommand = useRef<"save" | "recall" | "submit">();
  const { toast } = useToast();
  const form = useRef<HTMLFormElement>(null);
  const [selectedUser] = useSessionStorage<SelectUserByAdmin>(
    "select-user-by-admin",
    initSelectUserByAdmin
  );
  const [user] = useSessionStorage<UserData>("user", {
    isPM: false,
    isR: false,
    isRM: false,
    isAdmin: false,
    resourceId: null,
    maxHoursPerDay: 0,
  });
  const resourceId = useMemo(() => {
    if (user.resourceId !== "" && user.resourceId !== null) {
      return selectedUser.resourceId ? selectedUser.resourceId : user.resourceId;
    }
  }, [selectedUser]);

  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();



  const { update } = useFieldArray({
    control,
    name: "timesheet",
  });



  const { firstDay, lastDay } = useTableWeekPickerContext();
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (firstDay !== "" && lastDay !== "" && resourceId !== undefined) {
      setUrl(`/v1/Worklogs?resourceId=${resourceId}&dateFrom=${firstDay}&dateTo=${lastDay}`);
    }
  }, [firstDay, lastDay, resourceId]);

  const { url: selectUserUrl } = useSelectUserContext();

  useEffect(() => {
    if (firstDay !== "" && lastDay !== "") {
      selectUserUrl.setUrl(
        `/v1/Resources/GetNotSubmittingResources?dateFrom=${firstDay}&dateTo=${lastDay}`
      );
    }
  }, [firstDay, lastDay]);

  const [response, refresh] = useApi<TimesheetResponse[]>(url);

  useEffect(() => {
    if (response.data) {
      setValues(response.data);
      reset();
    }
  }, [response]);

  const { onOpenToggle } = useSpinnerContext();

  const modifyResponse = useMemo(
    () => fromTimesheetResponseToTimesheet(register, setValue, response.data),
    [response]
  ); // ove treba da se promeni sum

  const { isAnyApproved, isSubmitted } = useMemo(() => {
    if (response.data === undefined) {
      return { isAnyApproved: true, isSubmitted: true };
    }

    return {
      isAnyApproved: response.data.some((item) => item.isApproved),
      isSubmitted: response.data.some((item) => item.isSubmitted),
    };
  }, [response]);

  const { changeDisabled } = useTableInputContext();
  useEffect(() => {
    if (response.data !== undefined && response.data[0] !== undefined) {
      changeDisabled(isSubmitted);
    }
  }, [response.data]);

  const optionsProjects = useMemo(() => getProjectNames(response.data), [response]);
  const [visibleProjects, setVisibleProjects] = useState(valuesTimesheet);

  useEffect(() => {
    setVisibleProjects(valuesTimesheet);
  }, [valuesTimesheet]);

  useEffect(() => {
    setValuesTimesheet(modifyResponse);
  }, [modifyResponse]);

  const handleSave = (command: "save" | "recall" | "submit") => {
    lastCommand.current = command;
    form.current?.requestSubmit();

  };

  const onSubmit = async (inputData: any) => {
    const timesheets = fromTimesheetToTimesheetResponse(response.data, inputData.timesheet);

    onOpenToggle(true);
    try {
      await api(
        `/v1/Worklogs?adminResourceId=${user.resourceId}&resourceId=${resourceId}&command=${lastCommand.current}`,
        {
          method: "POST",
          data: timesheets,
        }
      );
      let commandMessage = "";
      if (lastCommand.current === Commands[3]) {
        commandMessage =
          "The timesheet has been successfully submitted to your manager for review.";
      } else if (lastCommand.current === Commands[4]) {
        commandMessage = "The timesheet has been successfully recalled.";
      } else if (lastCommand.current === Commands[2]) {
        commandMessage = "The timesheet has been successfully saved.";
      }
      toast({
        variant: "success",
        title: commandMessage,
      });
      refresh();
    } catch (error) {
      if (isApiError(error)) {
        toast({
          variant: "error",
          title: error.message,
        });
      } else {
        navigate("/default-error");
      }
    }

    onOpenToggle(false);
  };

  const { setFilters, setShowFilter } = useFiltersContext();

  useEffect(() => {
    if (valuesTimesheet.length === 0) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
    setFilters(
      <TimesheetFilter
        modifyResponse={valuesTimesheet}
        setVisibleProjects={setVisibleProjects}
        optionsProjects={optionsProjects}
      />
    );
  }, [valuesTimesheet, optionsProjects]);

  if (response.error) {
    return null;
  }

  if (!response.data) {
    return <SpinnerItem />;
  }

  return (
    <form ref={form} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col pt-5 gap-6">
        {isDesktop ? (
          <TimesheetFilter
            modifyResponse={valuesTimesheet}
            setVisibleProjects={setVisibleProjects}
            optionsProjects={optionsProjects}
          />
        ) : null}

        <section className="lg:px-8">
          <TimesheetTable
            data={visibleProjects}
            response={values}
            getRowsForHeader={getRowsForHeader}
            getRowsForFooter={getRowsForFooter}
          />
        </section>
        <section className="flex flex-col justify-between items-center mt-4 gap-4 lg:px-8 md:flex-row">
          <div className="flex flex-col gap-1 self-start md:self-auto">
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-[#F4F99B]"></div>
              <Text>- Submitted</Text>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-[#C5E8A8]"></div>
              <Text>- Approved</Text>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 bg-[#FBB5B5]"></div>
              <Text>- Rejected</Text>
            </div>
          </div>
          <div className="flex justify-center items-end gap-3">
            <Button
              appearance="secondary"
              onClick={() => handleSave("save")}
              disabled={isSubmitted || isAnyApproved}
            >
              Save
            </Button>
            <DialogFulfillsRecall
              disabled={!isSubmitted || isAnyApproved}
              handleRecall={() => handleSave("recall")}
            />
            <Button
              appearance="primary"
              disabled={isSubmitted || isAnyApproved}
              onClick={() => handleSave("submit")}
            >
              Submit
            </Button>
          </div>
        </section>
      </div>
    </form>
  );
}

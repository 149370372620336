import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { useState } from "react";

interface DialogFulfillsRecallProps {
  handleRecall: () => void;
  disabled: boolean;
}

export const DialogFulfillsRecall = ({ handleRecall, disabled }: DialogFulfillsRecallProps) => {
  const [open, setOpen] = useState(false);

  const handleCloseModel = () => {
    setOpen(false);
    handleRecall();
  };

  return (
    <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="outline" disabled={disabled}>
          Recall
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Warning</DialogTitle>
          <DialogContent>Are you sure you want to recall the submitted timesheet?</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={handleCloseModel}>
              Recall
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import React from "react";
import { cn } from "../../../lib/utils";

export interface FooterProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const FooterComponent = React.forwardRef<HTMLDivElement, FooterProps>(
  ({ className, ...props }, ref) => (
    <footer
      className={cn(
        "flex items-center justify-center mt-auto border-t border-gray-300 h-14",
        className
      )}
      ref={ref}
      {...props}
    />
  )
);

export default FooterComponent;

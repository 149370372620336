import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { useCollapsibleContext } from "./collapsible-context";

interface RowContentProps {
  className?: string;
  children: ReactNode;
}

export const CollapsibleContent = ({ className, ...props }: RowContentProps) => {
  const { open } = useCollapsibleContext();

 

  return <div className={cn("border-none", className, !open && "hidden")} {...props} />;
};

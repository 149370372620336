import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";

export interface DialogDataImportProps {
  title: string;
  description: string;
}

export const DialogDataImport = ({ title, description }: DialogDataImportProps) => {
  const dialogStyle = { 
    minWidth: 700,
    maxWidth: 1200
   };
   const descriptionStyle = {
    fontSize: '12px'
   }
  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button>View</Button>
      </DialogTrigger>
      <DialogSurface style={dialogStyle}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
            <DialogContent style={descriptionStyle}>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

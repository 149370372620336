import { DayColor } from "./Timesheet";
import { convertColor, sum, groupBy } from "../lib/utils";
import { v4 as uuidv4 } from "uuid";
import {
  TimesheetTable,
  TimesheetTableHeader,
  TimesheetTableTaskDays,
  TimesheetTableTasks,
} from "./TimesheetTable";
import { TableBox1 } from "../components/table-collapsible/table-box";
import { TableDate1 } from "../components/table-collapsible/table-date";
import { Command } from "../lib/command";

export interface TimesheetByRMResponse {
  timesheetId: string;
  projectId: string;
  projectFriendlyName: string;
  projectName: string;
  resourceId: string;
  resourceName: string;
  taskId: string;
  taskName: string;
  isLocked: boolean;
  isApproved: boolean;
  isSubmitted: boolean;
  isRejected: boolean;
  isProjectTask: boolean;
  availableHours: number;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  day1Hours: number;
  day2Hours: number;
  day3Hours: number;
  day4Hours: number;
  day5Hours: number;
  day6Hours: number;
  day7Hours: number;
  day1Color: string;
  day2Color: string;
  day3Color: string;
  day4Color: string;
  day5Color: string;
  day6Color: string;
  day7Color: string;
}

export interface TimesheetByRM {
  id: string;
  resourceId: string;
  resourceName: string;
  nameOfType: string;
  children: TimesheetTable[];
}

export const fromTimesheetByRMResponseToTimesheetByRM = (
  handleClick: (taskId: string, command: Command) => void,
  data?: TimesheetByRMResponse[]
): TimesheetByRM[] => {
  if (data === undefined) return [];

  const mapData = data.map((items) => ({
    id: items.projectId + "_" + items.projectFriendlyName,
    nameOfType: "TimesheetByPM",
    resourceId: items.resourceId,
    resourceName: items.resourceName,
    projectId: items.projectId,
    projectFriendlyName: items.projectFriendlyName,
    projectName: items.projectName,
    isLocked: items.isLocked,
    isApproved: items.isApproved,
    isSubmitted: items.isSubmitted,
    isRejected: items.isRejected,
    isProjectTask: items.isProjectTask,
    day1: new Date(items.day1),
    day2: new Date(items.day2),
    day3: new Date(items.day3),
    day4: new Date(items.day4),
    day5: new Date(items.day5),
    day6: new Date(items.day6),
    day7: new Date(items.day7),

    taskId: items.taskId,
    taskName: items.taskName,
    availableHours: {
      id: `${items.taskId}_availableHours`,
      hours: items.availableHours,
      color: convertColor("transparent"),
    },
    taskDay1: {
      id: `${items.taskId}_day1`,
      hours: items.day1Hours,
      color: convertColor(items.day1Color),
    },
    taskDay2: {
      id: `${items.taskId}_day2`,
      hours: items.day2Hours,
      color: convertColor(items.day2Color),
    },
    taskDay3: {
      id: `${items.taskId}_day3`,
      hours: items.day3Hours,
      color: convertColor(items.day3Color),
    },
    taskDay4: {
      id: `${items.taskId}_day4`,
      hours: items.day4Hours,
      color: convertColor(items.day4Color),
    },
    taskDay5: {
      id: `${items.taskId}_day5`,
      hours: items.day5Hours,
      color: convertColor(items.day5Color),
    },
    taskDay6: {
      id: `${items.taskId}_day6`,
      hours: items.day6Hours,
      color: convertColor(items.day6Color),
    },
    taskDay7: {
      id: `${items.taskId}_day7`,
      hours: items.day7Hours,
      color: convertColor(items.day7Color),
    },
    taskSum: {
      id: `${items.taskId}_sum`,
      hours: sum([
        items.day1Hours,
        items.day2Hours,
        items.day3Hours,
        items.day4Hours,
        items.day5Hours,
        items.day6Hours,
        items.day7Hours,
      ]),
      color: convertColor("transparent"),
    },
  }));

  const groupByResourceId = groupBy(mapData, "resourceId");
  const groupByResources = Object.values(groupByResourceId);

  return groupByResources.map((projects) => {
    const groupByProjectId = groupBy(projects, "projectId");
    const groupByProjects = Object.values(groupByProjectId);

    const formatedGroupByProjects = groupByProjects.map((items) => {
      const children: TimesheetTableTasks[] = [];

      items.forEach((item) => {
        children.push({
          id: item.taskId + "_" + item.taskName,
          taskId: item.taskId,
          taskName: item.taskName,

          isLocked: item.isLocked,
          isSubmitted: item.isSubmitted,
          isApproved: item.isApproved,
          isRejected: item.isRejected,

          children: [
            {
              id: item.taskDay1.id,
              key: "day1",
              hours: item.taskDay1.hours,
              node: (
                <TableBox1
                  day={item.taskDay1}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskDay2.id,
              key: "day2",
              hours: item.taskDay2.hours,
              node: (
                <TableBox1
                  day={item.taskDay2}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskDay3.id,
              key: "day3",
              hours: item.taskDay3.hours,
              node: (
                <TableBox1
                  day={item.taskDay3}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskDay4.id,
              key: "day4",
              hours: item.taskDay4.hours,
              node: (
                <TableBox1
                  day={item.taskDay4}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskDay5.id,
              key: "day5",
              hours: item.taskDay5.hours,
              node: (
                <TableBox1
                  day={item.taskDay5}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskDay6.id,
              key: "day6",
              hours: item.taskDay6.hours,
              node: (
                <TableBox1
                  day={item.taskDay6}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskDay7.id,
              key: "day7",
              hours: item.taskDay7.hours,
              node: (
                <TableBox1
                  day={item.taskDay7}
                  className="px-6 py-4 font-semibold"
                  showZero={false}
                />
              ),
            },
            {
              id: item.taskSum.id,
              key: "day7",
              hours: item.taskSum.hours,
              node: <TableBox1 day={item.taskSum} className="px-6 py-4 font-semibold" showZero />,
            },
          ],
        });
      });

      return {
        id: uuidv4(),
        nameOfType: items[0].nameOfType,
        projectId: items[0].projectId,
        projectFriendlyName: items[0].projectFriendlyName,
        projectName: items[0].projectName,
        day1: items[0].day1,
        day2: items[0].day2,
        day3: items[0].day3,
        day4: items[0].day4,
        day5: items[0].day5,
        day6: items[0].day6,
        day7: items[0].day7,
        children,
      };
    });

    return {
      id: uuidv4(),
      resourceId: projects[0].resourceId,
      resourceName: projects[0].resourceName,
      nameOfType: "TimesheetByPM",
      children: formatedGroupByProjects,
    };
  });
};

export const getRowsForHeader = (data?: TimesheetByRMResponse[]): TimesheetTableHeader[] => {
  if (data === undefined || data[0] === undefined) return [];
  const firstDay = data[0];

  return [
    {
      id: uuidv4(),
      node: <TableDate1 date={new Date(firstDay.day1)} />,
    },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day2)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day3)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day4)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day5)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day6)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day7)} /> },
    { id: uuidv4(), node: "Total" },
  ];
};

export const getRowsForFooter = (
  data?: TimesheetByRMResponse[],
  resourceId?: string
): TimesheetTableTaskDays[] => {
  if (data === undefined) return [];
  if (resourceId === undefined) return [];
  const dataGroupedByResourceId = data.filter((tm) => tm.resourceId === resourceId);
  if (dataGroupedByResourceId === undefined) return [];
  const sums = {
    sumAvailableHours: {
      id: uuidv4(),
      key: "availableHours",
      hours: sum(dataGroupedByResourceId.map((item) => item.availableHours)),
      color: "transparent" as DayColor,
    },
    sumDay1: {
      id: uuidv4(),
      key: "day1",
      hours: sum(dataGroupedByResourceId.map((item) => item.day1Hours)),
      color: "transparent" as DayColor,
    },
    sumDay2: {
      id: uuidv4(),
      key: "day2",
      hours: sum(dataGroupedByResourceId.map((item) => item.day2Hours)),
      color: "transparent" as DayColor,
    },
    sumDay3: {
      id: uuidv4(),
      key: "day3",
      hours: sum(dataGroupedByResourceId.map((item) => item.day3Hours)),
      color: "transparent" as DayColor,
    },
    sumDay4: {
      id: uuidv4(),
      key: "day4",
      hours: sum(dataGroupedByResourceId.map((item) => item.day4Hours)),
      color: "transparent" as DayColor,
    },
    sumDay5: {
      id: uuidv4(),
      key: "day5",
      hours: sum(dataGroupedByResourceId.map((item) => item.day5Hours)),
      color: "transparent" as DayColor,
    },
    sumDay6: {
      id: uuidv4(),
      key: "day6",
      hours: sum(dataGroupedByResourceId.map((item) => item.day6Hours)),
      color: "transparent" as DayColor,
    },
    sumDay7: {
      id: uuidv4(),
      key: "day7",
      hours: sum(dataGroupedByResourceId.map((item) => item.day7Hours)),
      color: "transparent" as DayColor,
    },
    sumTotal: { id: uuidv4(), key: "total", hours: 0, color: "transparent" as DayColor },
  };

  sums.sumTotal.hours = sum([
    sums.sumDay1.hours,
    sums.sumDay2.hours,
    sums.sumDay3.hours,
    sums.sumDay4.hours,
    sums.sumDay5.hours,
    sums.sumDay6.hours,
    sums.sumDay7.hours,
  ]);

  return [
    {
      id: uuidv4(),
      key: sums.sumDay1.key,
      hours: sums.sumDay1.hours,
      node: <TableBox1 day={sums.sumDay1} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumDay2.key,
      hours: sums.sumDay2.hours,
      node: <TableBox1 day={sums.sumDay2} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumDay3.key,
      hours: sums.sumDay3.hours,
      node: <TableBox1 day={sums.sumDay3} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumDay4.key,
      hours: sums.sumDay4.hours,
      node: <TableBox1 day={sums.sumDay4} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumDay5.key,
      hours: sums.sumDay5.hours,
      node: <TableBox1 day={sums.sumDay5} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumDay6.key,
      hours: sums.sumDay6.hours,
      node: <TableBox1 day={sums.sumDay6} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumDay7.key,
      hours: sums.sumDay7.hours,
      node: <TableBox1 day={sums.sumDay7} className="px-6 py-4 font-semibold" />,
    },
    {
      id: uuidv4(),
      key: sums.sumTotal.key,
      hours: sums.sumTotal.hours,
      node: <TableBox1 day={sums.sumTotal} className="px-6 py-4 font-semibold" />,
    },
  ];
};



export const getProjectNames = (data?: TimesheetByRMResponse[]) => {
  if (data === undefined) {
    return [];
  }
  const projectNames = data.map((item) => item.projectFriendlyName);
  return Array.from(new Set(projectNames));
};
export const getUsersNames = (data?: TimesheetByRMResponse[]) => {
  if (data === undefined) {
    return [];
  }
  const projectNames = data.map((item) => item.resourceName);
  return Array.from(new Set(projectNames));
};

import { ChangeEvent, useMemo, useState } from "react";
import { useApi } from "./useApi";
import { useToast } from "../components/ui/toast/use-toast";
import { DataImport } from "../models/DataImport";
import api from "../lib/apiBase";
import { ImportResult } from "../models/ImportResult";
import { useSpinnerContext } from "../components/spinner/spinner-context";

export interface useTest1Props {
  PAGE_FILE_NAME: string;
  firstApi: string;
  secoundApi: string;
}

export function useTest1({ PAGE_FILE_NAME, firstApi, secoundApi }: useTest1Props) {
  const [response, refresh] = useApi<DataImport[]>(firstApi);
  const { toast } = useToast();
  const { onOpenToggle } = useSpinnerContext();

  const [excelFile, setFile] = useState<File>();
  const isExcelFileValid = useMemo(
    () => excelFile?.name.toLowerCase().includes(PAGE_FILE_NAME),
    [excelFile]
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    //event.preventDefault();
    //event: FormEvent<HTMLFormElement>

    if (!excelFile) {
      return;
    }

    const fetchData = async () => {
      const formData = new FormData();
      formData.append("excelFile", excelFile);
      onOpenToggle(true);
      const data = await api<ImportResult>(secoundApi, {
        method: "POST",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Upload response: ", data);
      if (data.rowsError === 0) {
        toast({
          variant: "success",
          title: data.errorMessage ?? "",
        });

        console.log("data.errorMessage", data.errorMessage);
      } else {
        toast({
          variant: "error",
          title: data.errorMessage ?? "",
        });
      }
    };
    fetchData()
      .catch((error) => {
        console.log("error", error);
        let errorGlobal = "";
        if (error.errorMessage) errorGlobal = error.errorMessage;
        else if (error.message)
          errorGlobal =
            "An unknown network error has occurred. Please refresh the page and try again.";
        else errorGlobal = error;
        toast({
          variant: "error",
          title: errorGlobal,
        });
      })
      .finally(() => {

        onOpenToggle(false);
        refresh();
      });
  };

  return { response, isExcelFileValid, handleSubmit, handleFileChange, toast };
}

import { Toast, ToastBody, ToastTitle } from "@fluentui/react-components";
import { cn } from "../../lib/utils";

export interface IAlert {
  id: string;
  title: string;
  status: AlertStatusType;
  body?: string;
}

enum AlertStatus {
  Error = "Error",
  Success = "Success",
  Warning = "Warning",
  Info = "Info",
}

type AlertStatusType = keyof typeof AlertStatus;

export default function Alert({ title, status, body }: IAlert) {
  const { icon, classNameToast, classNameTitle, classNameBody } = toastType[status];
  return (
    <Toast className={cn(classNameToast)}>
      <ToastTitle className={cn(classNameTitle, "!font-semibold !text-lg")}>{title}</ToastTitle>
      {!!body && <ToastBody className={cn(classNameBody, "!text-base")}>{body}</ToastBody>}
    </Toast>
  );
}

const toastType = {
  Success: {
    icon: <p></p>,
    classNameToast: "!bg-green-50 dark:!bg-green-900",
    classNameTitle: "!text-green-700 dark:!text-green-400",
    classNameBody: "!text-green-700 dark:!text-green-400",
  },
  Error: {
    icon: <p></p>,
    classNameToast: "!bg-red-50 dark:!bg-red-900",
    classNameTitle: "!text-red-700 dark:!text-red-400",
    classNameBody: "!text-red-700 dark:!text-red-400",
  },
  Warning: {
    icon: <p></p>,
    classNameToast: "!bg-yellow-50 dark:!bg-yellow-900",
    classNameTitle: "!text-yellow-700 dark:!text-yellow-400",
    classNameBody: "!text-yellow-700 dark:!text-yellow-400",
  },
  Info: {
    icon: <p></p>,
    classNameToast: "!bg-gray-50 dark:!bg-gray-950 helper-alert-black",
    classNameTitle: "!text-gray-700 dark:!text-gray-400",
    classNameBody: "!text-gray-700 dakr:!text-gray-400",
  },
};

import { createContext, useContext } from "react";

type SpinnerContextValue = {
  open: boolean;
  onOpenToggle(data: any): void;
};

const SpinnerContext = createContext<SpinnerContextValue | null>(null);

export function useSpinnerContext() {
  const context = useContext(SpinnerContext);

  if (!context) {
    throw new Error(
      "spinner-component.* component must be rendered as child of spinner-component component"
    );
  }

  return context;
}

export default SpinnerContext;

import { ReactNode, createContext, useContext } from "react";
import { useToggle } from "../../hooks/useToggle";

interface CollapsibleContextValue {
  open: boolean;
  onOpenToggle: () => void;
}

const CollapsibleContext = createContext<CollapsibleContextValue | null>(null);

interface CollapsibleProviderProps {
  children: ReactNode;
}

export const CollapsibleProvider = ({ children }: CollapsibleProviderProps) => {
  const [value, toggle] = useToggle(true);

  return (
    <CollapsibleContext.Provider value={{ open: value, onOpenToggle: toggle }}>
      {children}
    </CollapsibleContext.Provider>
  );
};

export function useCollapsibleContext() {
  const context = useContext(CollapsibleContext);

  if (!context) {
    throw new Error("collapsible.* component must be rendered as child of collapsible component");
  }

  return context;
}

export default CollapsibleContext;

import { Button } from "@fluentui/react-components";
import { TimesheetTableFooter, TimesheetTableHeader } from "../../models/TimesheetTable";
import TableTimesheet from "../ui/table/table-timesheet";
import { CollapsibleProvider } from "../collapsible/collapsible-context";
import { CollapsibleTrigger } from "../collapsible/row-trigger";
import { CollapsibleContent } from "../collapsible/row-content";
import { TimesheetByRM, TimesheetByRMResponse } from "../../models/TimesheetByRM";
import { DialogTextArea } from "../dialog/dialog-text-area";

interface TimesheetByResourceManagerTableProps {
  data: TimesheetByRM[];
  response: TimesheetByRMResponse[];
  getRowsForHeader: (data: TimesheetByRMResponse[]) => TimesheetTableHeader[];
  getRowsForFooter: (data: TimesheetByRMResponse[]) => TimesheetTableFooter[];
  handleCommand: (command: any, resourceId: string, commandDescription: string) => void;
}

export default function TimesheetByResourceManagerTable({
  data,
  response,
  getRowsForHeader,
  getRowsForFooter,
  handleCommand,
}: TimesheetByResourceManagerTableProps) {
  if (data !== undefined) {
    return (
      <div>
        {data.map((resources) => (
          <CollapsibleProvider key={resources.resourceId}>
            <div className="mb-5">
              <CollapsibleTrigger>
                <h3 className="text-lg font-semibold text-slate-700 dark:text-slate-100 helper-text-black">{resources.resourceName}</h3>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <TableTimesheet<TimesheetByRMResponse>
                  data={resources.children}
                  response={response}
                  resourceId={resources.resourceId}
                  resourceName={resources.resourceName}
                  getRowsForHeader={getRowsForHeader}
                  getRowsForFooter={getRowsForFooter}
                />
              </CollapsibleContent>
              <div className="flex justify-end items-center gap-3 mt-4">
                <Button
                  appearance="primary"
                  onClick={() => handleCommand("approverm", resources.resourceId, "")}
                >
                  Approve
                </Button>
                <DialogTextArea
                  openDialogChildrenElement={<Button appearance="outline">
                    Reject...
                  </Button>}
                  dialogActionButtonName="Reject"
                  onDialogActionButton={(rejectMessage) => handleCommand("rejectrm", resources.resourceId, rejectMessage)}
                  title="Please provide the reason for rejecting this Timesheet"
                />
              </div>
            </div>
          </CollapsibleProvider>
        ))}
      </div>
    );
  }

  return <></>;
}

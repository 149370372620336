import { TableInput } from "../components/ui/table/tableInput";
import { TableBox1 } from "../components/table-collapsible/table-box";
import { TableDate1 } from "../components/table-collapsible/table-date";
import { convertColor, sum, groupBy } from "../lib/utils";
import {
  TimesheetTable,
  TimesheetTableHeader,
  TimesheetTableTaskDays,
  TimesheetTableTasks,
} from "./TimesheetTable";
import { v4 as uuidv4 } from "uuid";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

export interface TimesheetResponse {
  timesheetId: string;
  projectId: string;
  projectFriendlyName: string;
  projectName: string;
  taskId: string;
  resourceId: string | null;
  taskName: string;
  isLocked: boolean;
  isApproved: boolean;
  isSubmitted: boolean;
  isRejected: boolean;
  isProjectTask: boolean;
  availableHours: number;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  day1Hours: number;
  day2Hours: number;
  day3Hours: number;
  day4Hours: number;
  day5Hours: number;
  day6Hours: number;
  day7Hours: number;
  day1Color: string;
  day2Color: string;
  day3Color: string;
  day4Color: string;
  day5Color: string;
  day6Color: string;
  day7Color: string;
  minHoursPerDay: number | null;
  maxHoursPerDay: number;
}

export interface Timesheet extends TimesheetTable {
  nameOfType: string;
}

export type TimesheetDay = {
  id: string;
  hours: number;
  color: DayColor;
};

export const DayColors = [
  "green",
  "yellow",
  "red",
  "transparent",
  "white",
  "slate",
  undefined,
] as const;

export type DayColor = (typeof DayColors)[number];

export const fromTimesheetResponseToTimesheet = (
  register: UseFormRegister<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  data?: TimesheetResponse[]
): Timesheet[] => {
  if (data === undefined) return [];

  const mapData = data.map((items) => ({
    id: items.projectId + "_" + items.projectFriendlyName,
    nameOfType: "Timesheet",
    projectId: items.projectId,
    projectFriendlyName: items.projectFriendlyName,
    projectName: items.projectName,
    isLocked: items.isLocked,
    isApproved: items.isApproved,
    isSubmitted: items.isSubmitted,
    isRejected: items.isRejected,
    isProjectTask: items.isProjectTask,
    day1: new Date(items.day1),
    day2: new Date(items.day2),
    day3: new Date(items.day3),
    day4: new Date(items.day4),
    day5: new Date(items.day5),
    day6: new Date(items.day6),
    day7: new Date(items.day7),

    taskId: items.taskId,
    taskName: items.taskName,
    minHoursPerDay: items.minHoursPerDay,
    maxHoursPerDay: items.maxHoursPerDay,
    availableHours: {
      id: `${items.taskId}_availableHours`,
      // hours: items.availableHours,
      hours: sum([items.availableHours]),
      color: convertColor("transparent"),
    },
    taskDay1: {
      id: `${items.projectId}#@%${items.taskId}#@%day1`,
      // hours: items.day1Hours,
      hours: sum([items.day1Hours]),
      color: convertColor(items.day1Color),
    },
    taskDay2: {
      id: `${items.projectId}#@%${items.taskId}#@%day2`,
      // hours: items.day2Hours,
      hours: sum([items.day2Hours]),
      color: convertColor(items.day2Color),
    },
    taskDay3: {
      id: `${items.projectId}#@%${items.taskId}#@%day3`,
      // hours: items.day3Hours,
      hours: sum([items.day3Hours]),
      color: convertColor(items.day3Color),
    },
    taskDay4: {
      id: `${items.projectId}#@%${items.taskId}#@%day4`,
      // hours: items.day4Hours,
      hours: sum([items.day4Hours]),
      color: convertColor(items.day4Color),
    },
    taskDay5: {
      id: `${items.projectId}#@%${items.taskId}#@%day5`,
      // hours: items.day5Hours,
      hours: sum([items.day5Hours]),
      color: convertColor(items.day5Color),
    },
    taskDay6: {
      id: `${items.projectId}#@%${items.taskId}#@%day6`,
      // hours: items.day6Hours,
      hours: sum([items.day6Hours]),
      color: convertColor(items.day6Color),
    },
    taskDay7: {
      id: `${items.projectId}#@%${items.taskId}#@%day7`,
      // hours: items.day7Hours,
      hours: sum([items.day7Hours]),
      color: convertColor(items.day7Color),
    },
    taskSum: {
      id: `${items.projectId}#@%${items.taskId}#@%sum`,
      hours: sum([
        items.day1Hours,
        items.day2Hours,
        items.day3Hours,
        items.day4Hours,
        items.day5Hours,
        items.day6Hours,
        items.day7Hours,
      ]),
      color: convertColor("transparent"),
    },
  }));

  const groupByProjectId = groupBy(mapData, "projectId");
  const groupByProjects = Object.values(groupByProjectId);

  return groupByProjects.map((item) => {
    const children: TimesheetTableTasks[] = [];
    item.forEach((items) => {
      children.push({
        id: items.taskId + "_" + items.taskName,
        taskId: items.taskId,
        taskName: items.taskName,

        isLocked: items.isLocked,
        isSubmitted: items.isSubmitted,
        isApproved: items.isApproved,
        isRejected: items.isRejected,

        children: [
          {
            id: items.availableHours.id,
            key: "availableHours",
            hours: items.availableHours.hours,
            node: (
              <TableBox1
                day={items.availableHours}
                className="px-6 py-4 font-semibold text-slate-700 dark:text-slate-300"
                //Dont show zero in administrative projects
                showZero={items.projectId === "9999999999" ? false : true}
              />
            ),
          },
          {
            id: items.taskDay1.id,
            key: "day1",
            hours: items.taskDay1.hours,
            node: (
              <TableInput
                day={items.taskDay1}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskDay2.id,
            key: "day2",
            hours: items.taskDay2.hours,
            node: (
              <TableInput
                day={items.taskDay2}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskDay3.id,
            key: "day3",
            hours: items.taskDay3.hours,
            node: (
              <TableInput
                day={items.taskDay3}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskDay4.id,
            key: "day4",
            hours: items.taskDay4.hours,
            node: (
              <TableInput
                day={items.taskDay4}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskDay5.id,
            key: "day5",
            hours: items.taskDay5.hours,
            node: (
              <TableInput
                day={items.taskDay5}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskDay6.id,
            key: "day6",
            hours: items.taskDay6.hours,
            node: (
              <TableInput
                day={items.taskDay6}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskDay7.id,
            key: "day7",
            hours: items.taskDay7.hours,
            node: (
              <TableInput
                day={items.taskDay7}
                register={register}
                setValue={setValue}
                // minHours={items.minHoursPerDay}
                // maxHours={items.maxHoursPerDay}
                className="px-6 py-4"
              />
            ),
          },
          {
            id: items.taskSum.id,
            key: "sum",
            hours: items.taskSum.hours,
            node: (
              <TableBox1
                day={items.taskSum}
                className="px-6 py-4 font-semibold text-slate-700 dark:text-slate-300"
                showZero
              />
            ),
          },
        ],
      });
    });

    return {
      id: uuidv4(),
      nameOfType: item[0].nameOfType,
      projectId: item[0].projectId,
      projectFriendlyName: item[0].projectFriendlyName,
      projectName: item[0].projectName,

      minHoursPerDay: item[0].minHoursPerDay,
      maxHoursPerDay: item[0].maxHoursPerDay,
      day1: item[0].day1,
      day2: item[0].day2,
      day3: item[0].day3,
      day4: item[0].day4,
      day5: item[0].day5,
      day6: item[0].day6,
      day7: item[0].day7,
      children,
    };
  });
};

export const getRowsForHeader = (data?: TimesheetResponse[]): TimesheetTableHeader[] => {
  if (data === undefined || data[0] === undefined) return [];
  const firstDay = data[0];

  const firstRow: TimesheetTableHeader[] = [
    { id: uuidv4(), node: "Available Hrs" },
    {
      id: uuidv4(),
      node: <TableDate1 date={new Date(firstDay.day1)} />,
    },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day2)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day3)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day4)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day5)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day6)} /> },
    { id: uuidv4(), node: <TableDate1 date={new Date(firstDay.day7)} /> },
    { id: uuidv4(), node: "Total" },
  ];

  return firstRow;
};

export const getRowsForFooter = (data?: TimesheetResponse[]): TimesheetTableTaskDays[] => {
  if (data === undefined) return [];

  const sums = {
    sumAvailableHours: {
      id: uuidv4(),
      key: "availableHours",
      hours: sum(data.map((item) => item.availableHours)),
      color: "transparent" as DayColor,
    },
    sumDay1: {
      id: uuidv4(),
      key: "day1",
      hours: sum(data.map((item) => item.day1Hours)),
      color: "transparent" as DayColor,
    },
    sumDay2: {
      id: uuidv4(),
      key: "day2",
      hours: sum(data.map((item) => item.day2Hours)),
      color: "transparent" as DayColor,
    },
    sumDay3: {
      id: uuidv4(),
      key: "day3",
      hours: sum(data.map((item) => item.day3Hours)),
      color: "transparent" as DayColor,
    },
    sumDay4: {
      id: uuidv4(),
      key: "day4",
      hours: sum(data.map((item) => item.day4Hours)),
      color: "transparent" as DayColor,
    },
    sumDay5: {
      id: uuidv4(),
      key: "day5",
      hours: sum(data.map((item) => item.day5Hours)),
      color: "transparent" as DayColor,
    },
    sumDay6: {
      id: uuidv4(),
      key: "day6",
      hours: sum(data.map((item) => item.day6Hours)),
      color: "transparent" as DayColor,
    },
    sumDay7: {
      id: uuidv4(),
      key: "day7",
      hours: sum(data.map((item) => item.day7Hours)),
      color: "transparent" as DayColor,
    },
    sumTotal: { id: uuidv4(), key: "total", hours: 0, color: "transparent" as DayColor },
  };

  sums.sumTotal.hours = sum([
    sums.sumDay1.hours,
    sums.sumDay2.hours,
    sums.sumDay3.hours,
    sums.sumDay4.hours,
    sums.sumDay5.hours,
    sums.sumDay6.hours,
    sums.sumDay7.hours,
  ]);

  const sumsValues = Object.values(sums);

  return sumsValues.map<TimesheetTableTaskDays>((item) => ({
    id: item.id,
    key: item.key,
    hours: item.hours,
    color: item.color,
    node: (
      <TableBox1
        day={item}
        className="px-6 py-4 font-semibold text-slate-700 dark:text-slate-300"
      />
    ),
  }));
};

export const getProjectNames = (data?: TimesheetResponse[]) => {
  if (data === undefined) {
    return [];
  }
  const projectNames = data.map((item) => item.projectFriendlyName);
  return Array.from(new Set(projectNames));
};
export const getTaskNames = (data?: TimesheetResponse[]) => {
  if (data === undefined) {
    return [];
  }
  const taskNames = data.map((item) => item.taskName);
  return Array.from(new Set(taskNames));
};

export const fromTimesheetToTimesheetResponse = (
  data?: TimesheetResponse[],
  dataFromInputs?: any
) => {
  const response: TimesheetResponse[] = [];

  if (data === undefined) {
    return [];
  }

  for (const key in dataFromInputs) {
    let [projectId, taskId, day] = key.split("#@%");

    if (!response.some((r) => r.projectId === projectId && r.taskId === taskId)) {
      const timesheetEntry: TimesheetResponse = {
        timesheetId: "",
        projectId: projectId,
        projectFriendlyName: "",
        projectName: "",
        taskId: taskId,
        resourceId: "",
        isLocked: false,
        isSubmitted: false,
        isApproved: false,
        isRejected: false,
        isProjectTask: false,
        taskName: "",
        availableHours: 0,
        day1: data[0].day1,
        day2: data[0].day2,
        day3: data[0].day3,
        day4: data[0].day4,
        day5: data[0].day5,
        day6: data[0].day6,
        day7: data[0].day7,
        day1Hours: 0,
        day2Hours: 0,
        day3Hours: 0,
        day4Hours: 0,
        day5Hours: 0,
        day6Hours: 0,
        day7Hours: 0,
        day1Color: "",
        day2Color: "",
        day3Color: "",
        day4Color: "",
        day5Color: "",
        day6Color: "",
        day7Color: "",
        minHoursPerDay: 0,
        maxHoursPerDay: 0,
      };
      response.push(timesheetEntry);
    }

    const entry = response.find((r) => r.projectId === projectId && r.taskId === taskId);

    if (entry) {
      let dayValue = dataFromInputs[key];

      if (dayValue === "") dayValue = 0;

      switch (day) {
        case "day1":
          entry.day1Hours = parseFloat(dayValue);
          break;
        case "day2":
          entry.day2Hours = parseFloat(dayValue);
          break;
        case "day3":
          entry.day3Hours = parseFloat(dayValue);
          break;
        case "day4":
          entry.day4Hours = parseFloat(dayValue);
          break;
        case "day5":
          entry.day5Hours = parseFloat(dayValue);
          break;
        case "day6":
          entry.day6Hours = parseFloat(dayValue);
          break;
        case "day7":
          entry.day7Hours = parseFloat(dayValue);
          break;
        default:
          break;
      }
    }
  }

  for (let i = 0; i < response.length; i++) {
    response[i].resourceId = data[i].resourceId;
    response[i].isProjectTask = data[i].isProjectTask;
  }

  return response;
};

import TestSVG from "../../assets/svg/TestSVG";
import { Subtitle1, Subtitle2, Button } from "@fluentui/react-components";
import { FallbackProps } from "react-error-boundary";

import { isApiError } from "../../models/ApiError";

export default function ErrorFallbackComponent(props: FallbackProps) {
  const { error, resetErrorBoundary } = props;
  console.log(error, "InFallBackComponent");
  let title = "Something went wrong";
  let message = "An error has occurred, please contact your administrator.";
  if (isApiError(error)) {
    if (error.title) title = error.title;
    if (error.message) message = error.message;
  }
  return (
    <main className="flex flex-col justify-center items-center h-screen w-screen gap-4">
      <TestSVG />
      <Subtitle1 as="h1">{title}</Subtitle1>
      <Subtitle2 className="mb-12">{message}</Subtitle2>
      <div className="flex justify-end items-center gap-3">
        <Button appearance="primary" onClick={resetErrorBoundary}>
          Reload page
        </Button>
      </div>
    </main>
  );
}

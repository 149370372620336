import React, { ReactNode } from "react";
import { cn } from "../../../lib/utils";
import TableCollapsibleContext from "../table-collapsible-context";

interface TableMobileBodyCollapsibleProps {
  children: ReactNode;
  className?: string;
}

export const TableMobileBodyCollapsible = ({
  className,
  children,
}: TableMobileBodyCollapsibleProps) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TableCollapsibleContext.Provider
      value={{ open: isOpen, onOpenToggle: handleOpen, contentId: "" }}
    >
      <div className={cn("", className)}>{children}</div>
    </TableCollapsibleContext.Provider>
  );
};

import { AxiosRequestConfig } from "axios";
import api from "../lib/apiBase";
import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "../components/ui/toast/use-toast";
import { isApiError } from "../models/ApiError";

interface State<T> {
  data?: T;
  error?: Error;
}

type Cache<T> = { [url: string]: T };

// discriminated union type
type Action<T> =
  | { type: "loading" }
  | { type: "fetched"; payload: T }
  | { type: "error"; payload: Error };

export function useApi<T = unknown>(
  url: string,
  options?: AxiosRequestConfig
): [State<T>, () => void] {
  const cache = useRef<Cache<T>>({});
  const [refreshData, setRefreshData] = useState(0);

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState };
      case "fetched":
        return { ...initialState, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const refresh = () => {
    setRefreshData(refreshData + 1);
    cache.current = {};
  };

  useEffect(() => {
    cancelRequest.current = false;

    const fetchData = async () => {
      dispatch({ type: "loading" });

      if (url === "") {
        return;
      }

      // If a cache exists for this url, return it
      // if (cache.current[url]) {
      //   dispatch({ type: "fetched", payload: cache.current[url] });
      //   return;
      // }

      try {
        const response = await api<T>(url, options);

        cache.current[url] = response;
        if (cancelRequest.current) return;

        dispatch({ type: "fetched", payload: response });
      } catch (error) {
        console.log("error", error);
        if (cancelRequest.current) return;
        if (isApiError(error)) {
          toast({
            variant: "error",
            title: error.message,
          });
        } else {
          // otkomentarisati kada se popravi backend
          //navigate("/default-error");
        }
        dispatch({ type: "error", payload: error as Error });
      }
    };

    void fetchData();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, refreshData]);

  return [state, refresh];
}

import { ReactNode, createContext, useContext } from "react";

type FiltersContextValue = {
  filterNodes: ReactNode;
  setFilters: (filterNodes: ReactNode) => void;
  showFilter: boolean;
  setShowFilter: any;
};

const FiltersContext = createContext<FiltersContextValue | null>(null);

export function useFiltersContext() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error("filters.* component must be rendered as child of filters component");
  }

  return context;
}

export default FiltersContext;

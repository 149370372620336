import SHG_logo_light from "../../../assets/images/SHG_logo_light.png";
import SHG_logo_dark from "../../../assets/images/SHG_logo_dark.png";

const FooterBrand = () => (
  <>
    <img
      src={SHG_logo_light}
      alt="Memorial Sloan Kettering Cancer Center logo"
      className="w-auto h-6 dark:hidden helper-footer-logo-block"
    />
    <img
      src={SHG_logo_dark}
      alt="Memorial Sloan Kettering Cancer Center logo"
      className="w-auto h-6 hidden dark:block helper-footer-logo-hidden"
    />
  </>
);

export default FooterBrand;

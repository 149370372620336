import { Text } from "@fluentui/react-components";
import { cn } from "../../lib/utils";
import { getCurentWeekday, getCurentMonth } from "../../lib/date";
import { DESKTOP_MEDIA_QUERY } from "../../lib/constants";
import { useMediaQuery } from "usehooks-ts";

interface TableDate1Props {
  date: Date;
  className?: string;
}

export function TableDate1({ date, className }: TableDate1Props) {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  let classPom = "center";
  if (!isDesktop) classPom = "start";
  return (
    <div className={cn("flex flex-col items-" + classPom, className)}>
      <Text weight="bold" className="text-slate-900 dark:text-slate-400">
        {getCurentWeekday(date, isDesktop ? false : true)}
      </Text>
      <Text weight="semibold" className="text-slate-700 dark:text-slate-300">
        {getCurentMonth(date, true)} {date?.getDate()}
      </Text>
    </div>
  );
}

interface TableDateProps {
  date1: string;
  date2?: string;
  className?: string;
}

export function TableDate({ date1, date2, className }: TableDateProps) {
  return (
    <div className={cn("flex flex-col items-center", className)}>
      <Text weight="bold" className="text-slate-900 dark:text-slate-400">
        {date1}
      </Text>
      {date2 !== undefined ? (
        <Text weight="semibold" className="text-slate-700 dark:text-slate-300">
          {date2}
        </Text>
      ) : null}
    </div>
  );
}

import { Subtitle2 } from "@fluentui/react-components";
import TestSVG from "../assets/svg/TestSVG";
import { cn } from "../lib/utils";

interface ForbiddenImportProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export default function ForbiddenImport({ className, ...props }: ForbiddenImportProps) {
  return (
    <main
      className={cn(
        "flex flex-col justify-center items-center h-screen w-screen gap-4 p-3",
        className
      )}
      {...props}
    >
      <TestSVG />
      {/* <Subtitle1 as="h1">You are not authorized to access this page</Subtitle1> */}
      <Subtitle2 as="h3" className="mb-12">
        This feature is only available on the desktop version and isn't accessible through the
        mobile app. To use it, please switch to the desktop version.
      </Subtitle2>
    </main>
  );
}

import { createContext, useContext, useEffect, useMemo } from "react";
import { Timesheet } from "../../models/Timesheet";
import { TimesheetByPM } from "../../models/TimesheetByPM";
import { TimesheetByRM } from "../../models/TimesheetByRM";

type FilterContextValue = {
  response: Timesheet[] | TimesheetByPM[] | TimesheetByRM[];
  returnVisibleProjects: any;
  selectedProjects: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  selectedTasks: [string, React.Dispatch<React.SetStateAction<string>>];
  selectedUsers: [string[], React.Dispatch<React.SetStateAction<string[]>>];
};

const FilterContext = createContext<FilterContextValue | null>(null);

export function useFilterContext() {
  const context = useContext(FilterContext);

  const visibleProjects = useMemo(() => {
    if (!context) return undefined;
    const { response, selectedProjects, selectedTasks, selectedUsers } = context;
    const [selectedProject] = selectedProjects;
    const [selectedTask] = selectedTasks;
    const [selectedUser] = selectedUsers;

    if (selectedProject.length === 0 && selectedTask === "" && selectedUser.length === 0) {
      return response;
    }

    if (response[0].nameOfType === "Timesheet") {
      return forTimesheet(response as Timesheet[], selectedProject, selectedTask);
    }

    if (response[0].nameOfType === "TimesheetByPM") {
      return forTimesheetByPM(
        response as TimesheetByPM[],
        selectedProject,
        selectedTask,
        selectedUser
      );
    }

    if (response[0].nameOfType === "TimesheetByRM") {
      return forTimesheetByRM(
        response as TimesheetByRM[],
        selectedProject,
        selectedTask,
        selectedUser
      );
    }
  }, [
    context?.response,
    context?.selectedProjects,
    context?.selectedTasks,
    context?.selectedUsers,
  ]);

  useEffect(() => {
    if (!context || !visibleProjects) return;

    context.returnVisibleProjects(visibleProjects);
  }, [visibleProjects]);

  if (!context) {
    throw new Error("filter.* component must be rendered as child of FilterProvider component");
  }

  return {
    ...context,
  };
}

export default FilterContext;

const forTimesheet = (data: Timesheet[], selectedProjects: string[], selectedTasks: string) => {
  return data.filter((item) => {
    let matchesProject = true;
    if (selectedProjects.length !== 0) {
      matchesProject = selectedProjects.includes(item.projectFriendlyName);
    }

    let matchesTask = true;
    if (selectedTasks !== "") {
      matchesTask =
        selectedTasks !== "" &&
        item.children.some((i) => i.taskName.toLowerCase().includes(selectedTasks.toLowerCase()));
    }

    return matchesProject && matchesTask;
  });
};

const forTimesheetByPM = (
  data: TimesheetByPM[],
  selectedProjects: string[],
  selectedTasks: string,
  selectedUsers: string[]
): TimesheetByPM[] => {
  return data
    .map((pm) => {
      const filteredProjects = pm.children.filter((table) => {
        const projectMatch =
          selectedProjects.length === 0 || selectedProjects.includes(table.projectFriendlyName);

        const filteredTasks = table.children.filter(
          (task) =>
            selectedTasks === "" ||
            task.taskName.toLowerCase().includes(selectedTasks.toLowerCase())
        );

        return projectMatch && filteredTasks.length > 0;
      });

      return {
        ...pm,
        children: filteredProjects.map((project) => ({
          ...project,
          children: project.children.filter(
            (task) =>
              selectedTasks === "" ||
              task.taskName.toLowerCase().includes(selectedTasks.toLowerCase())
          ),
        })),
      };
    })
    .filter((pm) => {
      const userMatch = selectedUsers.length === 0 || selectedUsers.includes(pm.resourceName);
      return userMatch && pm.children.some((project) => project.children.length > 0);
    });
};

const forTimesheetByRM = (
  data: TimesheetByRM[],
  selectedProjects: string[],
  selectedTasks: string,
  selectedUsers: string[]
) => {
  //Maybe it will change in the future
  return forTimesheetByPM(data, selectedProjects, selectedTasks, selectedUsers);
};

import { DatePicker, DatePickerProps, DayOfWeek } from "@fluentui/react-datepicker-compat";
import { Field } from "@fluentui/react-components";
import { CalendarLtr16Regular } from "@fluentui/react-icons";
import { formatDatewithWeeks, formatDatewithWeeksForMobile } from "../../../lib/date";
import { useMediaQuery } from "usehooks-ts";
import { TABLE_MEDIA_QUERY } from "../../../lib/constants";

export const WeekCalendar = (props: Partial<DatePickerProps>) => {
  const isTableOrMobile = useMediaQuery(TABLE_MEDIA_QUERY);

  const onFormatDate = (date?: Date) => {
    if (date) {
      if (isTableOrMobile) {
        return formatDatewithWeeksForMobile(date);
      } else {
        return formatDatewithWeeks(date);
      }
    }
    return "";
  };

  return (
    <Field>
      <DatePicker
        formatDate={onFormatDate}
        className="w-36 sm:w-72"
        placeholder="Select a date..."
        firstDayOfWeek={DayOfWeek.Saturday}
        contentAfter={<CalendarLtr16Regular />}
        showWeekNumbers={true}
        {...props}
      />
    </Field>
  );
};

import { TimesheetByPM } from "../../models/TimesheetByPM";
import { Filter, FilterProjects, FilterTasks, FilterUsers } from "../filter/filter";

interface TimesheetByPMFilterProps {
  modifyResponse: TimesheetByPM[];
  setVisibleProjects: React.Dispatch<React.SetStateAction<TimesheetByPM[]>>;
  optionsProjects: string[];
  optionsUsers: string[];
}

export default function TimesheetByPMFilter({
  modifyResponse,
  setVisibleProjects,
  optionsProjects,
  optionsUsers,
}: TimesheetByPMFilterProps) {
  return (
    <Filter
      response={modifyResponse}
      returnVisibleProjects={setVisibleProjects}
      className="flex flex-col md:flex-row gap-5"
    >
      <FilterProjects options={optionsProjects} className="w-full md:w-1/5" />
      <FilterUsers options={optionsUsers} className="w-full md:w-1/5" />
      <FilterTasks className="w-full md:w-4/5" />
    </Filter>
  );
}

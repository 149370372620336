import TestSVG from "../assets/svg/TestSVG";
import { Subtitle1, Subtitle2 } from "@fluentui/react-components";

export default function NotAuthorised() {
  return (
    <main className="flex flex-col justify-center items-center h-screen w-screen gap-4">
      <TestSVG />
      <Subtitle1 as="h1">Your account is not enabled for MSK Timesheets</Subtitle1>
      <Subtitle2 className="mb-12">
        Please contact your administrator for access, or switch to an authorized account.
      </Subtitle2>
    </main>
  );
}

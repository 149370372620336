import { Text } from "@fluentui/react-components";
import TimesheetByResourceManagerTable from "../components/tables/timesheet-by-resource-manager-table";
import { useMediaQuery, useSessionStorage } from "usehooks-ts";
import { UserData } from "../models/UserData";
import { useTableWeekPickerContext } from "../components/week-picker/table-week-picker-context";
import { useSpinnerContext } from "../components/spinner/spinner-context";
import { useToast } from "../components/ui/toast/use-toast";
import { useEffect, useMemo, useState } from "react";
import { useApi } from "../hooks/useApi";
import { Command, Commands } from "../lib/command";
import {
  TimesheetByRMResponse,
  fromTimesheetByRMResponseToTimesheetByRM,
  getProjectNames,
  getUsersNames,
  getRowsForHeader,
  getRowsForFooter,
} from "../models/TimesheetByRM";
import TimesheetByRMFilter from "../components/filters/timesheet-by-rm-filter";
import { useFiltersContext } from "../components/filters/filters-context";
import { DESKTOP_MEDIA_QUERY } from "../lib/constants";
import api from "../lib/apiBase";
import Alert from "../components/alert/Alert";
import { SpinnerItem } from "../components/spinner/spinner";
import { SelectUserByAdmin, initSelectUserByAdmin } from "../components/select-users/select-users";
import { isApiError } from "../models/ApiError";
import { useSelectUserContext } from "../components/select-users/select-user-context";

const PAGE_FILE_NAME = "ApprovalsRM";

export default function TimesheetByResourceManager() {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const [selectedUser] = useSessionStorage<SelectUserByAdmin>(
    "select-user-by-admin",
    initSelectUserByAdmin
  );
  const [user] = useSessionStorage<UserData>("user", {
    isPM: false,
    isR: false,
    isRM: false,
    isAdmin: false,
    resourceId: null,
    maxHoursPerDay: 0,
  });
  const resourceId = useMemo(() => {
    if (user.resourceId !== "" && user.resourceId !== null) {
      return selectedUser.resourceId ? selectedUser.resourceId : user.resourceId;
    }
  }, [selectedUser]);
  const { firstDay, lastDay } = useTableWeekPickerContext();
  const { toast } = useToast();
  const [url, setUrl] = useState("");
  const { onOpenToggle } = useSpinnerContext();
  useEffect(() => {
    if (firstDay !== "" && lastDay !== "" && resourceId !== undefined) {
      setUrl(
        `/v1/Worklogs/rm-timesheets?resourceManagerId=${resourceId}&dateFrom=${firstDay}&dateTo=${lastDay}`
      );
    }
  }, [firstDay, lastDay, resourceId]);

  const { url: selectUserUrl, refresh: selectUserRefresh } = useSelectUserContext();

  useEffect(() => {
    if (firstDay !== "" && lastDay !== "") {
      selectUserUrl.setUrl(
        `/v1/Resources/GetNotApprovingRMs?dateFrom=${firstDay}&dateTo=${lastDay}`
      );
    }
  }, [firstDay, lastDay]);

  const [response, refresh] = useApi<TimesheetByRMResponse[]>(url);

  const handleCommandTask = async (taskId: string, command: Command) => {
    onOpenToggle(true);
    try {
      await api(`/v1/Worklogs?resourceId=${resourceId}&command=${command}`, {
        method: "POST",
        data: response.data,
      });
      let commandMessage = "";
      if (command === Commands[1]) {
        commandMessage = "The Timesheet has been successfully approved.";
      } else if (command === Commands[6]) {
        commandMessage = "The Timesheet has been successfully rejected.";
      }
      toast({
        variant: "success",
        title: commandMessage,
      });
      refresh();
    } catch (error) {}

    onOpenToggle(false);
  };

  const handleCommandResource = async (
    command: Command,
    _resourceId: string,
    commandDescripition: string
  ) => {
    onOpenToggle(true);
    try {
      await api(
        `/v1/Worklogs?adminResourceId=${user.resourceId}&resourceId=${_resourceId}&command=${command}&commandDescription=${commandDescripition}`,
        {
          method: "POST",
          data: response.data?.filter((item) => item.resourceId === _resourceId),
        }
      );
      let commandMessage = "";
      if (command === Commands[1]) {
        commandMessage = "The Timesheet has been successfully approved.";
      } else if (command === Commands[6]) {
        commandMessage = "The Timesheet has been successfully rejected.";
      }
      toast({
        variant: "success",
        title: commandMessage,
      });
      selectUserRefresh.current?.();
      refresh();
    } catch (error) {
      if (isApiError(error)) {
        toast({
          variant: "error",
          title: error.message,
        });
      } else {
        // otkomentarisati kada se popravi backend
        //navigate("/default-error");
      }
    }

    onOpenToggle(false);
  };

  const modifyResponse = useMemo(
    () => fromTimesheetByRMResponseToTimesheetByRM(handleCommandTask, response.data),
    [response]
  );

  const optionsProjects = useMemo(() => getProjectNames(response.data), [response]);
  const optionsUsers = useMemo(() => getUsersNames(response.data), [response]);

  const [visibleProjects, setVisibleProjects] = useState(modifyResponse);

  useEffect(() => {
    setVisibleProjects(modifyResponse);
  }, [modifyResponse]);

  const { setFilters, setShowFilter } = useFiltersContext();

  useEffect(() => {
    if (modifyResponse.length === 0) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
    setFilters(
      <TimesheetByRMFilter
        modifyResponse={modifyResponse}
        setVisibleProjects={setVisibleProjects}
        optionsProjects={optionsProjects}
        optionsUsers={optionsUsers}
      />
    );
  }, [modifyResponse, optionsProjects, optionsUsers]);

  if (response.error) {
    return null;
  }
  if (!response.data) {
    return <SpinnerItem />;
  }

  if (response.data.length === 0) {
    return (
      <section className="pt-5 lg:px-8">
        <Alert
          id="alert-info"
          title="There are no pending worklogs in the approval queue awaiting review."
          status="Info"
        />
      </section>
    );
  }

  return (
    <div className="flex flex-col pt-5 gap-6">
      {isDesktop ? (
        <TimesheetByRMFilter
          modifyResponse={modifyResponse}
          setVisibleProjects={setVisibleProjects}
          optionsProjects={optionsProjects}
          optionsUsers={optionsUsers}
        />
      ) : null}

      <section className="lg:px-8">
        <TimesheetByResourceManagerTable
          data={visibleProjects}
          response={response.data}
          getRowsForHeader={getRowsForHeader}
          getRowsForFooter={getRowsForFooter}
          handleCommand={handleCommandResource}
        />
      </section>

      <section className="flex flex-col justify-between items-center mt-4 gap-4 lg:px-8 md:flex-row">
        <div className="flex flex-col gap-1 self-start md:self-auto">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#F4F99B]"></div>
            <Text>- Submitted</Text>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#C5E8A8]"></div>
            <Text>- Approved</Text>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 bg-[#FBB5B5]"></div>
            <Text>- Rejected</Text>
          </div>
        </div>
      </section>
    </div>
  );
}

// workaround

import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { TimesheetResponse } from "../models/Timesheet";

const ValuesFromFormContext = createContext<{
  values: TimesheetResponse[];
  setValues: React.Dispatch<React.SetStateAction<TimesheetResponse[]>>;
  setValue: (id: string, currentValue: string) => void;
} | null>(null);

export function useValuesFromFormContext() {
  const context = useContext(ValuesFromFormContext);

  if (!context) {
    throw new Error("This context is for transfer values from Timesheet page");
  }

  return context;
}

export function ValuesFromFormContextProvider({ children }: { children: ReactNode }) {
  const [values, setValues] = useState<TimesheetResponse[]>([]);

  const valueIsChanged = useCallback(
    (id: string, currentValue: string) => {
      let [projectId, taskId, day] = id.split("#@%");

      if (currentValue === "") currentValue = "0";

      const temp = values.map((item) => {
        if (item.projectId === projectId && item.taskId === taskId) {
          switch (day) {
            case "day1":
              item.day1Hours = parseFloat(currentValue);
              break;
            case "day2":
              item.day2Hours = parseFloat(currentValue);
              break;
            case "day3":
              item.day3Hours = parseFloat(currentValue);
              break;
            case "day4":
              item.day4Hours = parseFloat(currentValue);
              break;
            case "day5":
              item.day5Hours = parseFloat(currentValue);
              break;
            case "day6":
              item.day6Hours = parseFloat(currentValue);
              break;
            case "day7":
              item.day7Hours = parseFloat(currentValue);
              break;
            default:
              break;
          }
        }
        return item;
      });
      setValues(temp);
    },
    [values]
  );

  return (
    <ValuesFromFormContext.Provider value={{ values, setValues, setValue: valueIsChanged }}>
      {children}
    </ValuesFromFormContext.Provider>
  );
}

export default ValuesFromFormContext;
